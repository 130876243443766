import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

interface UserFormFieldsProps {
  isEditing: boolean;
  isAdmin: boolean;
  form: any;
}

export function UserFormFields({ isEditing, isAdmin, form }: UserFormFieldsProps) {
  const email = form.watch('email');
  const role = form.watch('role');
  const originalEmail = form.formState.defaultValues.email;
  const originalRole = form.formState.defaultValues.role;
  const isEmailChanged = email !== originalEmail;
  const isRoleChanged = role !== originalRole;

  return (
    <div className="space-y-4">
      <FormField
        control={form.control}
        name="full_name"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Nome completo</FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="email"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Email</FormLabel>
            <FormControl>
              <Input {...field} disabled={!isAdmin && isEditing} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      {isAdmin && (
        <FormField
          control={form.control}
          name="role"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Função</FormLabel>
              <Select
                onValueChange={field.onChange}
                defaultValue={field.value}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Selecione uma função" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  <SelectItem value="user">Usuário</SelectItem>
                  <SelectItem value="admin">Administrador</SelectItem>
                </SelectContent>
              </Select>
              <FormMessage />
            </FormItem>
          )}
        />
      )}

      <FormField
        control={form.control}
        name="password"
        render={({ field }) => (
          <FormItem>
            <FormLabel>{isEditing ? "Nova senha" : "Senha"}</FormLabel>
            <FormControl>
              <Input
                type="password"
                {...field}
                placeholder={isEditing ? "Digite para alterar a senha" : ""}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="confirmPassword"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Confirmar senha</FormLabel>
            <FormControl>
              <Input
                type="password"
                {...field}
                placeholder={isEditing ? "Confirme a nova senha" : ""}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
}