import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { UseFormReturn } from "react-hook-form";
import { PostFormData } from "../postSchema";

interface PostExcerptFieldProps {
  form: UseFormReturn<PostFormData>;
}

export function PostExcerptField({ form }: PostExcerptFieldProps) {
  return (
    <FormField
      control={form.control}
      name="excerpt"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Resumo</FormLabel>
          <FormControl>
            <Textarea 
              placeholder="Digite um breve resumo do post" 
              {...field} 
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}