import { Calendar } from 'lucide-react';

interface BlogPostHeroProps {
  title: string;
  date: string;
  author: string;
  image: string;
}

export const BlogPostHero = ({ title, date, author, image }: BlogPostHeroProps) => {
  // Função para verificar se a URL é do Supabase
  const getImageUrl = (url: string) => {
    if (!url) return '/placeholder.svg';
    
    // Se já for uma URL completa do Supabase, use-a diretamente
    if (url.startsWith('https://')) {
      return url;
    }
    
    // Se for um caminho relativo, construa a URL completa
    return `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/site-agenciw3/${url}`;
  };

  return (
    <div className="relative h-[400px] w-full">
      <div className="absolute inset-0 bg-black/50 z-10" />
      <img
        src={getImageUrl(image)}
        alt={title}
        className="w-full h-full object-cover"
        onError={(e) => {
          console.log('Error loading image:', image);
          (e.target as HTMLImageElement).src = '/placeholder.svg';
        }}
      />
      <div className="absolute inset-0 z-20 flex items-center justify-center">
        <div className="container mx-auto px-4 text-center text-white">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">{title}</h1>
          <div className="flex items-center justify-center space-x-4 text-sm">
            <span className="flex items-center">
              <Calendar size={16} className="mr-2" />
              {new Date(date).toLocaleDateString('pt-BR')}
            </span>
            <span>por {author}</span>
          </div>
        </div>
      </div>
    </div>
  );
};