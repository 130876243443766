import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { Helmet } from 'react-helmet-async';
import { BarChart3, FileText, Settings, Users } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { toast } from 'sonner';

interface DashboardStats {
  totalPosts: number;
  totalServices: number;
  totalUsers: number;
}

export default function Dashboard() {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    console.log('Dashboard: Checking authentication...');
    supabase.auth.getSession().then(({ data: { session } }) => {
      console.log('Dashboard: Session status:', session ? 'Authenticated' : 'Not authenticated');
      if (!session) {
        console.log('Dashboard: No session, redirecting to login...');
        navigate('/admin/login');
      } else {
        console.log('Dashboard: Session found, loading dashboard...');
        setIsLoading(false);
      }
    });

    const { data: { subscription } } = supabase.auth.onAuthStateChange((event, session) => {
      console.log('Dashboard: Auth state changed:', event);
      if (event === 'SIGNED_OUT') {
        navigate('/admin/login');
      }
    });

    return () => subscription.unsubscribe();
  }, [navigate]);

  const { data: stats, error: statsError } = useQuery({
    queryKey: ['dashboard-stats'],
    queryFn: async () => {
      console.log('Dashboard: Fetching stats...');
      try {
        const [
          { count: postsCount, error: postsError }, 
          { count: servicesCount, error: servicesError }, 
          { count: usersCount, error: usersError }
        ] = await Promise.all([
          supabase.from('posts').select('*', { count: 'exact', head: true }),
          supabase.from('services').select('*', { count: 'exact', head: true }),
          supabase.from('profiles').select('*', { count: 'exact', head: true })
        ]);

        if (postsError) throw postsError;
        if (servicesError) throw servicesError;
        if (usersError) throw usersError;

        console.log('Dashboard: Stats fetched successfully:', { postsCount, servicesCount, usersCount });
        
        return {
          totalPosts: postsCount || 0,
          totalServices: servicesCount || 0,
          totalUsers: usersCount || 0
        } as DashboardStats;
      } catch (error) {
        console.error('Dashboard: Error fetching stats:', error);
        toast.error('Erro ao carregar estatísticas');
        throw error;
      }
    },
    enabled: !isLoading
  });

  if (statsError) {
    console.error('Dashboard: Error in stats query:', statsError);
  }

  if (isLoading) {
    console.log('Dashboard: Rendering loading state...');
    return (
      <div className="p-8">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  console.log('Dashboard: Rendering dashboard with stats:', stats);

  const cards = [
    {
      title: 'Total de Posts',
      value: stats?.totalPosts || 0,
      icon: FileText,
      color: 'bg-blue-500'
    },
    {
      title: 'Serviços',
      value: stats?.totalServices || 0,
      icon: Settings,
      color: 'bg-green-500'
    },
    {
      title: 'Usuários',
      value: stats?.totalUsers || 0,
      icon: Users,
      color: 'bg-purple-500'
    }
  ];

  return (
    <div className="p-8">
      <Helmet>
        <title>Dashboard - Painel Administrativo | Agência W3 Digital</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div className="mb-8">
        <h1 className="text-3xl font-bold">Dashboard</h1>
        <p className="text-gray-600">Bem-vindo ao painel administrativo</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mb-8">
        {cards.map((card, index) => (
          <div
            key={index}
            className="bg-white rounded-xl shadow-sm p-6 border border-gray-100"
          >
            <div className="flex items-center space-x-4">
              <div className={`${card.color} p-3 rounded-lg text-white`}>
                <card.icon size={24} />
              </div>
              <div>
                <h3 className="text-lg font-semibold text-gray-900">
                  {card.title}
                </h3>
                <p className="text-3xl font-bold">{card.value}</p>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="bg-white rounded-xl shadow-sm p-6 border border-gray-100">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold">Atividade Recente</h2>
          <BarChart3 className="text-gray-400" />
        </div>
        <p className="text-gray-600">
          Em breve: gráficos e estatísticas detalhadas sobre a atividade do site.
        </p>
      </div>
    </div>
  );
}