import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { CategoryForm } from '@/components/admin/categories/CategoryForm';
import { supabase } from '@/integrations/supabase/client';
import type { CategoryData } from '@/components/admin/categories/categorySchema';

export default function EditCategory() {
  const { id } = useParams<{ id: string }>();
  const isCreating = !id;

  const { data: category, isLoading } = useQuery({
    queryKey: ['category', id],
    queryFn: async () => {
      if (isCreating) return null;

      const { data, error } = await supabase
        .from('categories')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;
      
      return data as CategoryData;
    },
    enabled: !isCreating,
  });

  if (isLoading && !isCreating) {
    return (
      <div className="p-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 w-1/4 rounded" />
          <div className="h-4 bg-gray-200 w-1/2 rounded" />
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded" />
            <div className="h-4 bg-gray-200 rounded" />
            <div className="h-4 bg-gray-200 rounded" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">
          {isCreating ? "Nova Categoria" : "Editar Categoria"}
        </h1>
        <p className="text-gray-600 mt-2">
          {isCreating 
            ? "Preencha os dados para criar uma nova categoria"
            : "Faça as alterações necessárias na categoria"
          }
        </p>
      </div>

      <CategoryForm initialData={category} />
    </div>
  );
}