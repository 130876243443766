import { z } from 'zod';
import { Json } from '@/integrations/supabase/types';

const iconNames = [
  'code', 'database', 'globe', 'brain', 'mail', 'cloud', 'server', 'settings',
  'bell', 'calendar', 'camera', 'chevronRight', 'clock', 'coffee', 'computer',
  'creditCard', 'download', 'file', 'fileText', 'filter', 'flag', 'folder',
  'heart', 'home', 'image', 'key', 'link', 'lock', 'map', 'messageCircle',
  'music', 'package', 'phone', 'search', 'send', 'share', 'shield',
  'shoppingCart', 'star', 'sun', 'wrench', 'trash', 'user', 'video', 'wifi',
  'zap'
] as const;

export const serviceFormSchema = z.object({
  title: z.string().min(3, 'O título deve ter pelo menos 3 caracteres'),
  description: z.string().min(10, 'A descrição deve ter pelo menos 10 caracteres'),
  content: z.string().min(10, 'O conteúdo deve ter pelo menos 10 caracteres'),
  status: z.boolean().default(true),
  icon_name: z.enum(iconNames).default('code'),
  image_url: z.string({
    required_error: 'Faça upload de uma imagem para o serviço'
  }),
  benefits: z.array(z.string()).default([]),
});

export type ServiceFormData = z.infer<typeof serviceFormSchema>;

export interface ServiceData extends ServiceFormData {
  id?: string;
  created_by: string;
  updated_by: string;
  created_at?: string;
  updated_at?: string;
  slug?: string;
}