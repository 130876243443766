import { useState } from "react";
import { toast } from "sonner";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import InputMask from "react-input-mask";
import { supabase } from "@/integrations/supabase/client";
import emailjs from '@emailjs/browser';

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Buscar as configurações do EmailJS
      const { data: smtpSettings, error: smtpError } = await supabase
        .from('smtp_settings')
        .select('service_id, template_id, public_key')
        .order('updated_at', { ascending: false })
        .limit(1)
        .single();

      if (smtpError) {
        console.error('Erro ao buscar configurações SMTP:', smtpError);
        throw new Error('Erro ao buscar configurações SMTP');
      }

      if (!smtpSettings) {
        console.error('Nenhuma configuração SMTP encontrada');
        throw new Error('Configurações de email não encontradas');
      }

      // Inicializar o EmailJS com a chave pública
      emailjs.init(smtpSettings.public_key);

      // Preparar os parâmetros do template
      const templateParams = {
        from_name: formData.name,
        from_email: formData.email,
        phone: formData.phone,
        message: formData.message,
        to_email: 'atendimento@agenciaw3.digital',
        subject: 'Nova mensagem do formulário de contato',
      };

      // Enviar o email usando o EmailJS
      const response = await emailjs.send(
        smtpSettings.service_id,
        smtpSettings.template_id,
        templateParams
      );

      if (response.status === 200) {
        toast.success("Mensagem enviada com sucesso!");
        setFormData({
          name: "",
          email: "",
          phone: "",
          message: "",
        });
      } else {
        throw new Error("Erro ao enviar mensagem");
      }
    } catch (error: any) {
      console.error("Erro ao enviar formulário:", error);
      toast.error("Erro ao enviar mensagem. Por favor, tente novamente.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      <div>
        <label htmlFor="name" className="block text-sm font-medium text-gray-700 mb-1">
          Nome
        </label>
        <Input
          type="text"
          id="name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          placeholder="Seu nome completo"
          required
        />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div>
          <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
            E-mail
          </label>
          <Input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="seu@email.com"
            required
          />
        </div>

        <div>
          <label htmlFor="phone" className="block text-sm font-medium text-gray-700 mb-1">
            Telefone/WhatsApp
          </label>
          <InputMask
            mask="(99) 99999-9999"
            value={formData.phone}
            onChange={handleChange}
            name="phone"
            id="phone"
            required
          >
            {(inputProps: any) => (
              <Input
                {...inputProps}
                type="tel"
                placeholder="(00) 00000-0000"
              />
            )}
          </InputMask>
        </div>
      </div>

      <div>
        <label htmlFor="message" className="block text-sm font-medium text-gray-700 mb-1">
          Mensagem
        </label>
        <textarea
          id="message"
          name="message"
          rows={6}
          value={formData.message}
          onChange={handleChange}
          className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-primary focus:border-transparent"
          placeholder="Como podemos ajudar?"
          required
        />
      </div>

      <Button 
        type="submit" 
        className="w-full"
        disabled={isLoading}
      >
        {isLoading ? "Enviando..." : "Enviar Mensagem"}
      </Button>
    </form>
  );
};