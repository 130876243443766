export const ContactMap = () => {
  return (
    <div className="rounded-xl overflow-hidden h-[300px] bg-gray-100">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3714.4367637168166!2d-54.29021572526875!3d-22.231769179700374!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9489571505c2199d%3A0x3f6d9a2f4d305f31!2sR.%20Walter%20Hubacher%2C%201808%20-%20Centro%2C%20Nova%20Andradina%20-%20MS%2C%2079750-000!5e0!3m2!1spt-BR!2sbr!4v1708006827043!5m2!1spt-BR!2sbr"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </div>
  )
}