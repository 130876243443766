import React from 'react';

interface ServiceHeroProps {
  title: string;
  description: string;
  imageUrl: string | null;
}

export const ServiceHero = ({ title, description, imageUrl }: ServiceHeroProps) => {
  return (
    <div className="relative h-[400px] w-full">
      <div className="absolute inset-0 bg-black/50 z-10" />
      <img
        src={imageUrl || 'https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80'}
        alt={title}
        className="w-full h-full object-cover"
      />
      <div className="absolute inset-0 z-20 flex items-center justify-center">
        <div className="container mx-auto px-4 text-center text-white">
          <h1 className="text-4xl md:text-5xl font-bold mb-4">{title}</h1>
          <p className="text-xl max-w-2xl mx-auto">{description}</p>
        </div>
      </div>
    </div>
  );
};