import { BrowserRouter, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Toaster } from "sonner";
import { Helmet, HelmetProvider } from "react-helmet-async";
import Index from './pages/Index';
import About from './pages/About';
import Contact from './pages/Contact';
import Services from './pages/Services';
import ServiceDetails from './pages/ServiceDetails';
import Blog from './pages/Blog';
import BlogPost from './pages/BlogPost';
import NotFound from './pages/NotFound';
import Login from './pages/admin/Login';
import Dashboard from './pages/admin/Dashboard';
import AdminServices from './pages/admin/Services';
import EditService from './pages/admin/EditService';
import EditPost from './pages/admin/EditPost';
import AdminBlog from './pages/admin/Blog';
import Users from './pages/admin/Users';
import Categories from './pages/admin/Categories';
import EditCategory from './pages/admin/EditCategory';
import { AdminLayout } from '@/components/layouts/AdminLayout';
import { useAuth } from '@/hooks/useAuth';
import SmtpSettings from '@/pages/admin/settings/smtp';
import GoogleTags from '@/pages/admin/settings/google-tags';
import SiteInfo from '@/pages/admin/settings/site-info';
import SocialNetworks from '@/pages/admin/settings/social-networks';
import { GoogleTagsComponent } from '@/components/google/GoogleTagsComponent';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { user, loading } = useAuth();

  if (loading) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
    </div>;
  }

  if (!user) {
    return <Navigate to="/admin/login" replace />;
  }

  return <>{children}</>;
};

function App() {
  return (
    <HelmetProvider>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <GoogleTagsComponent />
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/sobre" element={<About />} />
            <Route path="/contato" element={<Contact />} />
            <Route path="/servicos" element={<Services />} />
            <Route path="/servicos/:slug" element={<ServiceDetails />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/blog/:slug" element={<BlogPost />} />
            <Route path="/admin/login" element={<Login />} />
            
            {/* Rotas protegidas do admin */}
            <Route path="/admin" element={<ProtectedRoute><AdminLayout><Outlet /></AdminLayout></ProtectedRoute>}>
              <Route index element={<Navigate to="/admin/dashboard" replace />} />
              <Route path="dashboard" element={<Dashboard />} />
              <Route path="services" element={<AdminServices />} />
              <Route path="services/new" element={<EditService />} />
              <Route path="services/edit/:id" element={<EditService />} />
              <Route path="blog" element={<AdminBlog />} />
              <Route path="blog/new" element={<EditPost />} />
              <Route path="blog/edit/:id" element={<EditPost />} />
              <Route path="users" element={<Users />} />
              <Route path="categories" element={<Categories />} />
              <Route path="categories/new" element={<EditCategory />} />
              <Route path="categories/edit/:id" element={<EditCategory />} />
              
              {/* Rotas de configurações */}
              <Route path="settings">
                <Route path="smtp" element={<SmtpSettings />} />
                <Route path="google-tags" element={<GoogleTags />} />
                <Route path="site-info" element={<SiteInfo />} />
                <Route path="social-networks" element={<SocialNetworks />} />
              </Route>
            </Route>

            <Route path="*" element={<NotFound />} />
          </Routes>
          <Toaster position="top-right" expand={false} richColors />
        </BrowserRouter>
      </QueryClientProvider>
    </HelmetProvider>
  );
}

export default App;