import { z } from 'zod';

export const categoryFormSchema = z.object({
  name: z.string().min(3, 'O nome deve ter pelo menos 3 caracteres'),
  description: z.string().optional(),
});

export type CategoryFormData = z.infer<typeof categoryFormSchema>;

export interface CategoryData extends CategoryFormData {
  id: string;
  slug: string;
  created_at: string;
  updated_at: string;
}