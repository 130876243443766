import { BaseLayout } from '../components/layouts/BaseLayout';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

const About = () => {
  // Schema.org JSON-LD para a página Sobre
  const aboutSchema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "Agência W3 Digital",
    "description": "Somos uma empresa apaixonada por tecnologia e inovação, criando soluções digitais que transformam negócios.",
    "url": "https://agenciaw3.digital",
    "logo": "https://agenciaw3.digital/logomarca.png",
    "foundingDate": "2020",
    "address": {
      "@type": "PostalAddress",
      "addressCountry": "BR"
    },
    "sameAs": [
      "https://www.linkedin.com/company/agenciaw3digital",
      "https://www.instagram.com/agenciaw3digital"
    ]
  };

  return (
    <BaseLayout>
      <Helmet>
        <title>Sobre a Agência W3 Digital | Inovação e Tecnologia</title>
        <meta name="description" content="Conheça a história da Agência W3 Digital, uma empresa focada em inovação e tecnologia, criando soluções digitais que transformam negócios." />
        <meta name="keywords" content="agência digital, tecnologia, inovação, desenvolvimento web, marketing digital, história agência w3" />
        <link rel="canonical" href="https://agenciaw3.digital/sobre" />
        <script type="application/ld+json">
          {JSON.stringify(aboutSchema)}
        </script>
      </Helmet>

      <div className="min-h-screen pt-28">
        <div className="container mx-auto px-4">
          {/* Hero Section */}
          <div className="text-center mb-16 animate-fade-up">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">
              Sobre a Agência W3
            </h1>
            <p className="text-xl text-gray-600 max-w-3xl mx-auto">
              Somos uma empresa apaixonada por tecnologia e inovação, 
              criando soluções digitais que transformam negócios.
            </p>
          </div>

          {/* História */}
          <div className="grid md:grid-cols-2 gap-12 mb-16">
            <div className="space-y-6">
              <h2 className="text-3xl font-bold">Nossa História</h2>
              <p className="text-gray-600">
                Fundada com a visão de transformar o mercado digital, a Agência W3 
                tem se destacado no desenvolvimento de soluções tecnológicas inovadoras.
                Nossa jornada começou com um compromisso simples: entregar excelência
                em cada projeto.
              </p>
              <Link 
                to="/contato" 
                className="btn-primary inline-flex items-center"
                aria-label="Entre em contato conosco"
              >
                Fale Conosco
                <ArrowRight className="ml-2" size={20} aria-hidden="true" />
              </Link>
            </div>
            <div className="bg-gradient-to-br from-primary/10 to-accent/10 rounded-2xl p-8">
              <img 
                src="/agenciaw3.png"
                alt="Recepção Agência W3" 
                className="rounded-xl w-full h-[400px] object-cover shadow-lg"
                loading="lazy"
              />
            </div>
          </div>

          {/* Missão, Visão e Valores */}
          <div className="grid md:grid-cols-3 gap-8 mb-16">
            <div className="p-6 rounded-xl border border-gray-100 hover:border-primary/20 transition-colors duration-200">
              <h3 className="text-2xl font-bold mb-4 gradient-text">Missão</h3>
              <p className="text-gray-600">
                Desenvolver soluções digitais inovadoras que impulsionem o sucesso 
                dos nossos clientes no mundo digital.
              </p>
            </div>
            <div className="p-6 rounded-xl border border-gray-100 hover:border-primary/20 transition-colors duration-200">
              <h3 className="text-2xl font-bold mb-4 gradient-text">Visão</h3>
              <p className="text-gray-600">
                Ser referência em transformação digital, reconhecida pela excelência
                e inovação em nossas soluções.
              </p>
            </div>
            <div className="p-6 rounded-xl border border-gray-100 hover:border-primary/20 transition-colors duration-200">
              <h3 className="text-2xl font-bold mb-4 gradient-text">Valores</h3>
              <ul className="text-gray-600 space-y-2">
                <li>• Inovação constante</li>
                <li>• Compromisso com resultados</li>
                <li>• Excelência técnica</li>
                <li>• Transparência</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default About;