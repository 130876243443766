import React from 'react';
import { BaseLayout } from '../components/layouts/BaseLayout';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { BlogPostHero } from '../components/blog/BlogPostHero';
import { BlogPostNavigation } from '../components/blog/BlogPostNavigation';
import { BlogPostShare } from '../components/blog/BlogPostShare';
import { supabase } from '@/integrations/supabase/client';
import { useQuery } from '@tanstack/react-query';
import { Helmet } from 'react-helmet-async';

interface Profile {
  full_name: string | null;
}

interface Post {
  id: string;
  title: string;
  content: string;
  published_at: string | null;
  created_at: string;
  image_url: string | null;
  created_by: string;
  profiles: Profile;
}

const BlogPost = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const { data: post, isLoading } = useQuery({
    queryKey: ['post', slug],
    queryFn: async () => {
      console.log('Fetching post with slug:', slug);
      
      // Primeiro, buscar o post
      const { data: postData, error: postError } = await supabase
        .from('posts')
        .select('*')
        .eq('slug', slug)
        .eq('published', true)
        .single();

      if (postError) {
        console.error('Error fetching post:', postError);
        navigate('/blog');
        return null;
      }

      if (!postData) {
        console.log('No post found');
        navigate('/blog');
        return null;
      }

      // Depois, buscar o perfil do autor
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('full_name')
        .eq('id', postData.created_by)
        .maybeSingle();

      console.log('Post data:', postData);
      console.log('Profile data:', profileData);
      console.log('Profile error:', profileError);
      
      // Se não encontrar o perfil ou houver erro, usa um valor padrão
      const authorProfile = profileData || { full_name: 'Equipe W3' };
      
      return {
        ...postData,
        profiles: authorProfile
      } as Post;
    },
  });

  // Schema.org JSON-LD para o artigo do blog
  const articleSchema = post ? {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    "headline": post.title,
    "image": post.image_url ? `https://agenciaw3.digital${post.image_url}` : undefined,
    "datePublished": post.published_at || post.created_at,
    "dateModified": post.created_at,
    "author": {
      "@type": "Person",
      "name": post.profiles.full_name || "Equipe W3"
    },
    "publisher": {
      "@type": "Organization",
      "name": "Agência W3 Digital",
      "logo": {
        "@type": "ImageObject",
        "url": "https://agenciaw3.digital/logomarca.png"
      }
    },
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `https://agenciaw3.digital/blog/${slug}`
    }
  } : null;

  if (isLoading) {
    return (
      <BaseLayout>
        <div className="min-h-screen pt-20">
          <div className="animate-pulse">
            <div className="h-[400px] bg-gray-200"></div>
            <div className="container mx-auto px-4 py-8">
              <div className="max-w-4xl mx-auto">
                <div className="h-8 bg-gray-200 rounded w-3/4 mb-4"></div>
                <div className="h-4 bg-gray-200 rounded w-1/4 mb-8"></div>
                <div className="space-y-4">
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                  <div className="h-4 bg-gray-200 rounded w-full"></div>
                  <div className="h-4 bg-gray-200 rounded w-3/4"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BaseLayout>
    );
  }

  if (!post) {
    return (
      <BaseLayout>
        <div className="container mx-auto px-4 pt-24">
          <h1 className="text-2xl font-bold">Post não encontrado</h1>
          <Link 
            to="/blog" 
            className="text-primary hover:text-primary-dark"
            aria-label="Voltar para a lista de posts"
          >
            Voltar para o blog
          </Link>
        </div>
      </BaseLayout>
    );
  }

  return (
    <BaseLayout>
      <Helmet>
        <title>{`${post.title} | Blog Agência W3 Digital`}</title>
        <meta name="description" content={post.content.substring(0, 160)} />
        <meta 
          name="keywords" 
          content={`${post.title.toLowerCase()}, blog tecnologia, marketing digital, desenvolvimento web`} 
        />
        <link rel="canonical" href={`https://agenciaw3.digital/blog/${slug}`} />
        {articleSchema && (
          <script type="application/ld+json">
            {JSON.stringify(articleSchema)}
          </script>
        )}
      </Helmet>

      <article className="min-h-screen pt-20">
        <BlogPostHero
          title={post.title}
          date={post.published_at || post.created_at}
          author={post.profiles.full_name || 'Equipe W3'}
          image={post.image_url || '/placeholder.svg'}
        />

        <div className="container mx-auto px-4 py-8">
          <BlogPostNavigation title={post.title} />

          <div className="max-w-4xl mx-auto">
            <div 
              className="prose prose-lg max-w-none"
              dangerouslySetInnerHTML={{ __html: post.content }}
            />

            <BlogPostShare />
          </div>
        </div>
      </article>
    </BaseLayout>
  );
};

export default BlogPost;