import { X, Menu } from 'lucide-react';

interface AdminHeaderProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  userFullName: string | null;
}

export function AdminHeader({ isSidebarOpen, setIsSidebarOpen, userFullName }: AdminHeaderProps) {
  return (
    <header className="bg-white border-b border-gray-200 h-16 fixed top-0 right-0 left-0 z-30 lg:left-64">
      <div className="flex items-center justify-between h-full px-4">
        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="lg:hidden p-2 rounded-lg hover:bg-gray-50"
        >
          {isSidebarOpen ? (
            <X className="w-6 h-6" />
          ) : (
            <Menu className="w-6 h-6" />
          )}
        </button>
        <div className="ml-auto text-sm text-gray-600">
          Olá, {userFullName || 'Usuário'}
        </div>
      </div>
    </header>
  );
}