import { useQuery } from '@tanstack/react-query';
import { Code, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import { useState } from 'react';
import { 
  Pagination, 
  PaginationContent, 
  PaginationItem, 
  PaginationLink, 
  PaginationNext, 
  PaginationPrevious 
} from "@/components/ui/pagination";
import * as Icons from 'lucide-react';

const ITEMS_PER_PAGE = 8;

export const ServicesList = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading, error } = useQuery({
    queryKey: ['services', currentPage],
    queryFn: async () => {
      console.log('Fetching services...');
      const startRange = (currentPage - 1) * ITEMS_PER_PAGE;
      const endRange = startRange + ITEMS_PER_PAGE - 1;

      const { data: services, count, error } = await supabase
        .from('services')
        .select('*', { count: 'exact' })
        .eq('status', true)
        .order('title')
        .range(startRange, endRange);
      
      if (error) {
        console.error('Error fetching services:', error);
        throw error;
      }

      console.log('Services fetched:', services);
      return { services, totalCount: count || 0 };
    }
  });

  const totalPages = data ? Math.ceil(data.totalCount / ITEMS_PER_PAGE) : 0;

  if (isLoading) {
    return (
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="animate-pulse space-y-4">
            {[...Array(8)].map((_, i) => (
              <div key={i} className="h-48 bg-gray-200 rounded-xl" />
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    console.error('Error loading services:', error);
    return (
      <div className="text-center py-20">
        <p className="text-red-500">Erro ao carregar serviços. Por favor, tente novamente mais tarde.</p>
      </div>
    );
  }

  if (!data?.services?.length) {
    return (
      <div className="text-center py-20">
        <p className="text-gray-600">Nenhum serviço disponível no momento. Por favor, volte mais tarde.</p>
      </div>
    );
  }

  const getIconComponent = (iconName: string | null) => {
    if (!iconName) {
      console.warn('No icon name provided, using default Code icon');
      return Code;
    }
    
    // Primeira letra maiúscula para corresponder ao formato dos nomes dos ícones no lucide-react
    const formattedIconName = iconName.charAt(0).toUpperCase() + iconName.slice(1);
    const IconComponent = (Icons as any)[formattedIconName];
    
    if (!IconComponent) {
      console.warn(`Icon "${iconName}" not found, using default Code icon`);
      return Code;
    }
    
    return IconComponent;
  };

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Nossos Serviços</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Oferecemos soluções completas em tecnologia para impulsionar seu negócio
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {data?.services.map((service) => {
            const IconComponent = getIconComponent(service.icon_name);
            console.log('Service icon_name:', service.icon_name);
            console.log('Icon Component:', IconComponent?.name);
            
            return (
              <Link
                key={service.id}
                to={`/servicos/${service.slug}`}
                className="group"
              >
                <div className="p-6 rounded-xl border border-gray-100 hover:border-primary/20 transition-all duration-200 h-full hover:-translate-y-1">
                  <IconComponent
                    size={40}
                    className="text-primary mb-4 group-hover:text-accent transition-colors duration-200"
                  />
                  <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                  <p className="text-gray-600 mb-4">{service.description}</p>
                  <span className="text-primary group-hover:text-accent inline-flex items-center">
                    Saiba mais
                    <ArrowRight className="ml-2" size={16} />
                  </span>
                </div>
              </Link>
            );
          })}
        </div>

        {totalPages > 1 && (
          <div className="mt-12">
            <Pagination>
              <PaginationContent>
                <PaginationItem>
                  <PaginationPrevious 
                    onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                    className={currentPage === 1 ? 'pointer-events-none opacity-50' : 'cursor-pointer'}
                  />
                </PaginationItem>

                {[...Array(totalPages)].map((_, i) => (
                  <PaginationItem key={i + 1}>
                    <PaginationLink
                      onClick={() => setCurrentPage(i + 1)}
                      isActive={currentPage === i + 1}
                      className="cursor-pointer"
                    >
                      {i + 1}
                    </PaginationLink>
                  </PaginationItem>
                ))}

                <PaginationItem>
                  <PaginationNext
                    onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                    className={currentPage === totalPages ? 'pointer-events-none opacity-50' : 'cursor-pointer'}
                  />
                </PaginationItem>
              </PaginationContent>
            </Pagination>
          </div>
        )}

        <div className="mt-24 py-16 px-4 sm:px-12 rounded-3xl bg-gradient-to-r from-primary/5 via-primary/10 to-accent/5">
          <div className="text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Pronto para transformar seu negócio?
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto mb-8">
              Entre em contato conosco para discutir como podemos ajudar sua empresa a alcançar seus objetivos digitais.
            </p>
            <Link
              to="/contato"
              className="inline-flex items-center px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-200"
            >
              Fale Conosco
              <ArrowRight className="ml-2" size={16} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};