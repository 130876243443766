import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Editor } from "@/components/ui/editor";
import { UseFormReturn } from "react-hook-form";
import { ServiceFormData } from "./serviceSchema";

interface ServiceContentFieldProps {
  form: UseFormReturn<ServiceFormData>;
}

export function ServiceContentField({ form }: ServiceContentFieldProps) {
  return (
    <FormField
      control={form.control}
      name="content"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Conteúdo</FormLabel>
          <FormControl>
            <Editor 
              value={field.value}
              onChange={field.onChange}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}