import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { categoryFormSchema, type CategoryFormData, type CategoryData } from './categorySchema';
import { CategoryNameField } from './form/CategoryNameField';
import { CategoryDescriptionField } from './form/CategoryDescriptionField';

interface CategoryFormProps {
  initialData?: CategoryData;
}

export function CategoryForm({ initialData }: CategoryFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const form = useForm<CategoryFormData>({
    resolver: zodResolver(categoryFormSchema),
    defaultValues: initialData ? {
      name: initialData.name,
      description: initialData.description,
    } : {
      name: '',
      description: '',
    },
  });

  const onSubmit = async (data: CategoryFormData) => {
    try {
      setIsLoading(true);
      
      // O trigger do Supabase irá gerar o slug automaticamente
      const { error } = initialData?.id
        ? await supabase
            .from('categories')
            .update({
              name: data.name,
              description: data.description,
            })
            .eq('id', initialData.id)
        : await supabase
            .from('categories')
            .insert({
              name: data.name,
              description: data.description,
            });

      if (error) throw error;

      toast.success(initialData ? "Categoria atualizada com sucesso!" : "Categoria cadastrada com sucesso!");
      navigate('/admin/categories');
      
    } catch (error) {
      console.error('Erro ao salvar categoria:', error);
      toast.error("Ocorreu um erro ao salvar a categoria.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <CategoryNameField form={form} />
        <CategoryDescriptionField form={form} />

        <div className="flex justify-end">
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Salvando..." : (initialData ? "Atualizar" : "Cadastrar")}
          </Button>
        </div>
      </form>
    </Form>
  );
}