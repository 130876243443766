import { supabase } from "@/integrations/supabase/client";
import type { FormData } from "./CreateUserFormSchema";

export async function createUser(data: FormData) {
  console.log("=== Iniciando criação de usuário ===");
  console.log("Dados do formulário:", {
    email: data.email,
    full_name: data.full_name,
    role: data.role,
  });

  // Primeiro, vamos verificar se o usuário já existe
  const { data: existingUser } = await supabase
    .from('profiles')
    .select('id')
    .eq('id', data.email)
    .single();

  if (existingUser) {
    console.log("Usuário já existe:", existingUser);
    throw new Error("Este email já está em uso");
  }

  console.log("Chamando supabase.auth.signUp...");
  const { data: authData, error: signUpError } = await supabase.auth.signUp({
    email: data.email,
    password: data.password || '', // Garante que password seja string mesmo quando opcional
    options: {
      data: {
        full_name: data.full_name,
        role: data.role,
      },
      emailRedirectTo: `${window.location.origin}/auth/callback`,
    },
  });

  console.log("Resposta do signUp:", {
    success: !!authData?.user,
    userId: authData?.user?.id,
    error: signUpError ? {
      message: signUpError.message,
      status: signUpError.status,
      name: signUpError.name
    } : null
  });

  if (signUpError) {
    console.error("Erro detalhado na criação do usuário:", signUpError);
    throw signUpError;
  }

  if (!authData.user) {
    console.error("Nenhum usuário retornado após signUp");
    throw new Error("Falha ao criar usuário: resposta inválida do servidor");
  }

  console.log("Usuário criado com sucesso:", {
    id: authData.user.id,
    email: authData.user.email,
    role: data.role
  });

  return authData.user;
}