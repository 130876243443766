interface Post {
  title: string;
  excerpt: string;
  image_url: string | null;
  published_at: string | null;
  created_at: string;
  slug: string;
}

interface BlogSchemaProps {
  posts: Post[];
}

export const BlogSchema = ({ posts }: BlogSchemaProps) => {
  const blogSchema = {
    "@context": "https://schema.org",
    "@type": "Blog",
    "name": "Blog Agência W3",
    "description": "Fique por dentro das últimas novidades e tendências do mundo digital.",
    "url": "https://agenciaw3.digital/blog",
    "publisher": {
      "@type": "Organization",
      "name": "Agência W3 Digital",
      "logo": {
        "@type": "ImageObject",
        "url": "https://agenciaw3.digital/logomarca.png"
      }
    },
    "blogPost": posts?.map(post => ({
      "@type": "BlogPosting",
      "headline": post.title,
      "description": post.excerpt,
      "image": post.image_url,
      "datePublished": post.published_at || post.created_at,
      "url": `https://agenciaw3.digital/blog/${post.slug}`
    }))
  };

  return (
    <script type="application/ld+json">
      {JSON.stringify(blogSchema)}
    </script>
  );
};