import { useState } from "react";
import { useForm } from "react-hook-form";
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Form } from "@/components/ui/form";
import { Button } from "@/components/ui/button";
import { toast } from "sonner";
import { formSchema, type FormData } from "./CreateUserFormSchema";
import { zodResolver } from "@hookform/resolvers/zod";
import { UserFormFields } from "./UserFormFields";
import { createUser } from "./userService";
import { useAuth } from "@/hooks/useAuth";

interface CreateUserFormProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
}

export function CreateUserForm({ isOpen, onClose, onSuccess }: CreateUserFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const isAdmin = user?.role === 'admin';

  const form = useForm<FormData>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
      full_name: "",
      role: "user",
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      setIsLoading(true);
      await createUser(data);
      toast.success("Usuário criado com sucesso!");
      form.reset();
      onSuccess();
      onClose();
    } catch (error: any) {
      console.error("Erro ao criar usuário:", error);
      toast.error(error.message || "Erro ao criar usuário");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Criar Novo Usuário</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <UserFormFields form={form} isEditing={false} isAdmin={true} />
            <div className="flex justify-end gap-2">
              <Button type="button" variant="outline" onClick={onClose}>
                Cancelar
              </Button>
              <Button type="submit" disabled={isLoading}>
                {isLoading ? "Criando..." : "Criar Usuário"}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}