import React from 'react';

interface ServiceContentProps {
  content: string;
}

export const ServiceContent = ({ content }: ServiceContentProps) => {
  return (
    <div 
      className="prose prose-lg max-w-none mb-12"
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};