import { FormControl, FormField, FormItem, FormLabel } from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { UseFormReturn } from "react-hook-form";
import { ServiceFormData } from "../serviceSchema";

interface ServiceStatusFieldProps {
  form: UseFormReturn<ServiceFormData>;
}

export function ServiceStatusField({ form }: ServiceStatusFieldProps) {
  return (
    <FormField
      control={form.control}
      name="status"
      render={({ field }) => (
        <FormItem className="flex flex-row items-center justify-between rounded-lg border p-4">
          <div className="space-y-0.5">
            <FormLabel className="text-base">
              Status
            </FormLabel>
            <div className="text-sm text-muted-foreground">
              Ative ou desative a exibição deste serviço no site
            </div>
          </div>
          <FormControl>
            <Switch
              checked={field.value}
              onCheckedChange={field.onChange}
            />
          </FormControl>
        </FormItem>
      )}
    />
  );
}