import { BaseLayout } from '../components/layouts/BaseLayout';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Helmet } from 'react-helmet-async';
import { BlogHero } from '@/components/blog/BlogHero';
import { BlogGrid } from '@/components/blog/BlogGrid';
import { BlogSchema } from '@/components/blog/BlogSchema';
import { useState } from 'react';
import { 
  Pagination, 
  PaginationContent, 
  PaginationItem, 
  PaginationLink, 
  PaginationNext, 
  PaginationPrevious 
} from "@/components/ui/pagination";

const ITEMS_PER_PAGE = 6;

const Blog = () => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, isLoading } = useQuery({
    queryKey: ['posts', currentPage],
    queryFn: async () => {
      const startRange = (currentPage - 1) * ITEMS_PER_PAGE;
      const endRange = startRange + ITEMS_PER_PAGE - 1;

      const { data: posts, count, error } = await supabase
        .from('posts')
        .select('*', { count: 'exact' })
        .eq('published', true)
        .order('published_at', { ascending: false })
        .range(startRange, endRange);

      if (error) {
        console.error('Error fetching posts:', error);
        throw error;
      }

      return { posts: posts || [], totalCount: count || 0 };
    },
  });

  const totalPages = data ? Math.ceil(data.totalCount / ITEMS_PER_PAGE) : 0;

  return (
    <BaseLayout>
      <Helmet>
        <title>Blog Agência W3 Digital | Novidades em Tecnologia e Marketing Digital</title>
        <meta 
          name="description" 
          content="Fique por dentro das últimas novidades e tendências do mundo digital. Artigos sobre tecnologia, marketing digital, desenvolvimento web e muito mais." 
        />
        <meta 
          name="keywords" 
          content="blog tecnologia, marketing digital, desenvolvimento web, tendências digitais, inovação" 
        />
        <link rel="canonical" href="https://agenciaw3.digital/blog" />
      </Helmet>

      <BlogSchema posts={data?.posts || []} />

      <div className="min-h-screen pt-28">
        <BlogHero 
          title="Blog Agência W3"
          description="Fique por dentro das últimas novidades e tendências do mundo digital."
        />

        <div className="container mx-auto px-4">
          <BlogGrid posts={data?.posts || []} isLoading={isLoading} />

          {totalPages > 1 && (
            <div className="mt-12 mb-16">
              <Pagination>
                <PaginationContent>
                  <PaginationItem>
                    <PaginationPrevious 
                      onClick={() => setCurrentPage(p => Math.max(1, p - 1))}
                      className={currentPage === 1 ? 'pointer-events-none opacity-50' : 'cursor-pointer'}
                    />
                  </PaginationItem>

                  {[...Array(totalPages)].map((_, i) => (
                    <PaginationItem key={i + 1}>
                      <PaginationLink
                        onClick={() => setCurrentPage(i + 1)}
                        isActive={currentPage === i + 1}
                        className="cursor-pointer"
                      >
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}

                  <PaginationItem>
                    <PaginationNext
                      onClick={() => setCurrentPage(p => Math.min(totalPages, p + 1))}
                      className={currentPage === totalPages ? 'pointer-events-none opacity-50' : 'cursor-pointer'}
                    />
                  </PaginationItem>
                </PaginationContent>
              </Pagination>
            </div>
          )}
        </div>
      </div>
    </BaseLayout>
  );
};

export default Blog;