import { Link } from 'react-router-dom';
import { Facebook, Instagram, Linkedin, Mail, MapPin, Phone } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';

export const Footer = () => {
  const { data: siteInfo } = useQuery({
    queryKey: ['site-info'],
    queryFn: async () => {
      console.log('Fetching site info for Footer...');
      const { data, error } = await supabase
        .from('site_info')
        .select('address, email, phone')
        .limit(1)
        .single();

      if (error) {
        console.error('Error fetching site info in Footer:', error);
        throw error;
      }
      
      console.log('Site info data received in Footer:', data);
      return data;
    },
    retry: 3,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const services = [
    { title: 'Desenvolvimento Web', slug: 'desenvolvimento-web' },
    { title: 'Apps Mobile', slug: 'apps-mobile' },
    { title: 'Marketing Digital', slug: 'marketing-digital' },
    { title: 'Agentes de IA', slug: 'agentes-ia' },
    { title: 'Backup Corporativo', slug: 'backup-corporativo' },
    { title: 'Email Corporativo', slug: 'email-corporativo' },
    { title: 'Hospedagem de Sites', slug: 'hospedagem-sites' },
    { title: 'Cloud Server', slug: 'cloud-server' }
  ];

  return (
    <footer className="bg-secondary text-white py-12 mt-20">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Logo e Sobre */}
          <div className="space-y-4">
            <Link to="/" className="block">
              <img
                src="/logomarca.png"
                alt="Agência W3"
                className="h-8"
              />
            </Link>
            <p className="text-gray-300 text-sm">
              Transformando ideias em soluções digitais inovadoras para impulsionar seu negócio.
            </p>
          </div>

          {/* Links Rápidos */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Links Rápidos</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/" className="text-gray-300 hover:text-primary transition-colors">
                  Início
                </Link>
              </li>
              <li>
                <Link to="/sobre" className="text-gray-300 hover:text-primary transition-colors">
                  Sobre
                </Link>
              </li>
              <li>
                <Link to="/servicos" className="text-gray-300 hover:text-primary transition-colors">
                  Serviços
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-300 hover:text-primary transition-colors">
                  Blog
                </Link>
              </li>
              <li>
                <Link to="/contato" className="text-gray-300 hover:text-primary transition-colors">
                  Contato
                </Link>
              </li>
            </ul>
          </div>

          {/* Serviços */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Nossos Serviços</h3>
            <ul className="space-y-2">
              {services.map((service, index) => (
                <li key={index}>
                  <Link 
                    to={`/servicos/${service.slug}`}
                    className="text-gray-300 hover:text-primary transition-colors"
                  >
                    {service.title}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contato */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Contato</h3>
            <ul className="space-y-4">
              <li className="flex items-start">
                <MapPin className="text-primary mt-1 mr-2" size={20} />
                <span className="text-gray-300">
                  {siteInfo?.address || 'Rua Walter Hubacher, 1808 - Centro Nova Andradina - MS'}
                </span>
              </li>
              <li className="flex items-center">
                <Phone className="text-primary mr-2" size={20} />
                <span className="text-gray-300">{siteInfo?.phone || '(67) 3441-9114'}</span>
              </li>
              <li className="flex items-center">
                <Mail className="text-primary mr-2" size={20} />
                <span className="text-gray-300">{siteInfo?.email || 'atendimento@agenciaw3.digital'}</span>
              </li>
            </ul>
            {/* Redes Sociais */}
            <div className="flex space-x-4 mt-6">
              <a 
                href="https://www.facebook.com/agenciaw3.digital" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-300 hover:text-primary transition-colors"
              >
                <Facebook size={20} />
              </a>
              <a 
                href="https://www.instagram.com/agenciaw3.digital" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-300 hover:text-primary transition-colors"
              >
                <Instagram size={20} />
              </a>
              <a 
                href="https://www.linkedin.com/company/ag%C3%AAncia-w3case/" 
                target="_blank" 
                rel="noopener noreferrer" 
                className="text-gray-300 hover:text-primary transition-colors"
              >
                <Linkedin size={20} />
              </a>
            </div>
          </div>
        </div>

        {/* Copyright */}
        <div className="border-t border-gray-700 mt-12 pt-8 text-center text-gray-400">
          <p>&copy; {new Date().getFullYear()} Agência W3. Todos os direitos reservados.</p>
        </div>
      </div>
    </footer>
  );
};