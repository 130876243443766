import { Link } from "react-router-dom";
import { Button } from "@/components/ui/button";
import { Plus } from "lucide-react";
import { PostsTable } from "@/components/admin/posts/PostsTable";

export default function Blog() {
  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-3xl font-bold">Gerenciamento do Blog</h1>
          <p className="text-gray-600 mt-2">
            Gerencie os posts exibidos no site
          </p>
        </div>
        <Link to="/admin/blog/new">
          <Button>
            <Plus className="mr-2 h-4 w-4" />
            Novo Post
          </Button>
        </Link>
      </div>

      <PostsTable />
    </div>
  );
}