import { Facebook, Twitter, Linkedin } from 'lucide-react';

export const BlogPostShare = () => {
  return (
    <div className="border-t border-gray-200 mt-12 pt-8">
      <h3 className="text-lg font-semibold mb-4">Compartilhe este artigo</h3>
      <div className="flex space-x-4">
        <button
          onClick={() => window.open(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`, '_blank')}
          className="p-2 rounded-full bg-blue-600 text-white hover:bg-blue-700 transition-colors"
          aria-label="Compartilhar no Facebook"
        >
          <Facebook size={20} />
        </button>
        <button
          onClick={() => window.open(`https://twitter.com/intent/tweet?url=${window.location.href}`, '_blank')}
          className="p-2 rounded-full bg-sky-500 text-white hover:bg-sky-600 transition-colors"
          aria-label="Compartilhar no Twitter"
        >
          <Twitter size={20} />
        </button>
        <button
          onClick={() => window.open(`https://www.linkedin.com/shareArticle?mini=true&url=${window.location.href}`, '_blank')}
          className="p-2 rounded-full bg-blue-700 text-white hover:bg-blue-800 transition-colors"
          aria-label="Compartilhar no LinkedIn"
        >
          <Linkedin size={20} />
        </button>
      </div>
    </div>
  );
};