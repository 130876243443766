import { Link } from 'react-router-dom';
import { ArrowRight, Calendar } from 'lucide-react';

interface Post {
  id: string;
  title: string;
  slug: string;
  excerpt: string;
  image_url: string | null;
  published_at: string;
  created_at: string;
}

interface BlogGridProps {
  posts: Post[];
  isLoading: boolean;
}

export const BlogGrid = ({ posts, isLoading }: BlogGridProps) => {
  // Função para obter a URL correta da imagem
  const getImageUrl = (url: string | null) => {
    if (!url) return '/placeholder.svg';
    
    if (url.startsWith('https://')) {
      return url;
    }
    
    return `${import.meta.env.VITE_SUPABASE_URL}/storage/v1/object/public/site-agenciw3/${url}`;
  };

  if (isLoading) {
    return (
      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
        {[1, 2, 3, 4, 5, 6].map((index) => (
          <div key={index} className="animate-pulse">
            <div className="bg-gray-200 h-48 rounded-t-xl"></div>
            <div className="p-6 border border-gray-100 rounded-b-xl">
              <div className="h-4 bg-gray-200 rounded w-1/4 mb-3"></div>
              <div className="h-6 bg-gray-200 rounded w-3/4 mb-3"></div>
              <div className="h-4 bg-gray-200 rounded w-full mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/4"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
      {posts?.map((post) => (
        <Link
          key={post.id}
          to={`/blog/${post.slug}`}
          className="group"
          aria-label={`Ler o artigo: ${post.title}`}
        >
          <div className="rounded-xl overflow-hidden border border-gray-100 hover:border-primary/20 transition-all duration-200 h-full hover:-translate-y-1">
            <div className="relative h-48">
              <img
                src={getImageUrl(post.image_url)}
                alt={post.title}
                className="w-full h-full object-cover"
                loading="lazy"
                onError={(e) => {
                  console.error('Error loading image:', post.image_url);
                  (e.target as HTMLImageElement).src = '/placeholder.svg';
                }}
              />
            </div>
            <div className="p-6">
              <div className="flex items-center text-gray-500 text-sm mb-3">
                <Calendar size={16} className="mr-2" aria-hidden="true" />
                {new Date(post.published_at || post.created_at).toLocaleDateString('pt-BR')}
              </div>
              <h2 className="text-xl font-semibold mb-3 group-hover:text-primary transition-colors">
                {post.title}
              </h2>
              <p className="text-gray-600 mb-4">
                {post.excerpt}
              </p>
              <span className="text-primary group-hover:text-accent inline-flex items-center">
                Ler mais
                <ArrowRight className="ml-2" size={16} aria-hidden="true" />
              </span>
            </div>
          </div>
        </Link>
      ))}
    </div>
  );
};