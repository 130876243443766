import { z } from 'zod';

export const smtpSchema = z.object({
  service_id: z.string().min(1, 'Service ID é obrigatório'),
  template_id: z.string().min(1, 'Template ID é obrigatório'),
  public_key: z.string().min(1, 'Public Key é obrigatória'),
});

export type SmtpFormData = z.input<typeof smtpSchema>;
export type SmtpSettings = z.output<typeof smtpSchema> & {
  id?: string;
};