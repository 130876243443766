import { useState } from 'react';
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Plus, X } from "lucide-react";
import { UseFormReturn } from "react-hook-form";
import { ServiceFormData } from "../serviceSchema";

interface ServiceBenefitsFieldProps {
  form: UseFormReturn<ServiceFormData>;
}

export function ServiceBenefitsField({ form }: ServiceBenefitsFieldProps) {
  const [newBenefit, setNewBenefit] = useState('');

  const addBenefit = () => {
    if (newBenefit.trim()) {
      const currentBenefits = form.getValues('benefits') || [];
      form.setValue('benefits', [...currentBenefits, newBenefit.trim()]);
      setNewBenefit('');
    }
  };

  const removeBenefit = (index: number) => {
    const currentBenefits = form.getValues('benefits') || [];
    form.setValue('benefits', currentBenefits.filter((_, i) => i !== index));
  };

  return (
    <FormField
      control={form.control}
      name="benefits"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Benefícios</FormLabel>
          <div className="space-y-4">
            <div className="flex gap-2">
              <Input
                placeholder="Digite um benefício e pressione adicionar"
                value={newBenefit}
                onChange={(e) => setNewBenefit(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && (e.preventDefault(), addBenefit())}
              />
              <Button 
                type="button" 
                onClick={addBenefit}
                variant="outline"
              >
                <Plus className="w-4 h-4" />
              </Button>
            </div>
            <div className="space-y-2">
              {field.value?.map((benefit, index) => (
                <div key={index} className="flex items-center gap-2 bg-secondary/20 p-2 rounded-md">
                  <span className="flex-1">{benefit}</span>
                  <Button
                    type="button"
                    variant="ghost"
                    size="sm"
                    onClick={() => removeBenefit(index)}
                  >
                    <X className="w-4 h-4" />
                  </Button>
                </div>
              ))}
            </div>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}