import { Link, useLocation } from 'react-router-dom';
import { LucideIcon } from 'lucide-react';

export interface MenuItemType {
  title: string;
  icon: LucideIcon;
  path: string;
  subItems?: {
    title: string;
    icon: LucideIcon;
    path: string;
  }[];
}

interface MenuItemProps {
  item: MenuItemType;
  isBlogMenuOpen: boolean;
  setIsBlogMenuOpen: (isOpen: boolean) => void;
  isSettingsMenuOpen: boolean;
  setIsSettingsMenuOpen: (isOpen: boolean) => void;
}

export function MenuItem({ 
  item, 
  isBlogMenuOpen, 
  setIsBlogMenuOpen,
  isSettingsMenuOpen,
  setIsSettingsMenuOpen 
}: MenuItemProps) {
  const location = useLocation();
  const isActive = location.pathname === item.path;
  const hasSubItems = item.subItems && item.subItems.length > 0;
  const isSubItemActive = hasSubItems && item.subItems.some(
    subItem => location.pathname === subItem.path
  );

  const handleMenuClick = (e: React.MouseEvent) => {
    if (hasSubItems) {
      e.preventDefault();
      if (item.title === 'Blog') {
        setIsBlogMenuOpen(!isBlogMenuOpen);
      } else if (item.title === 'Configurações') {
        setIsSettingsMenuOpen(!isSettingsMenuOpen);
      }
    }
  };

  const isMenuOpen = item.title === 'Blog' ? isBlogMenuOpen : 
                     item.title === 'Configurações' ? isSettingsMenuOpen : 
                     false;

  return (
    <div>
      <Link
        to={hasSubItems ? '#' : item.path}
        onClick={handleMenuClick}
        className={`flex items-center px-4 py-2 text-gray-600 rounded-lg transition-colors ${
          (isActive || isSubItemActive)
            ? 'bg-primary text-white hover:bg-primary/90' 
            : 'hover:bg-gray-50 hover:text-primary'
        }`}
      >
        <item.icon className={`w-5 h-5 mr-3 ${(isActive || isSubItemActive) ? 'text-white' : ''}`} />
        {item.title}
      </Link>

      {hasSubItems && isMenuOpen && (
        <div className="ml-6 mt-1 space-y-1">
          {item.subItems.map((subItem) => {
            const isSubActive = location.pathname === subItem.path;
            return (
              <Link
                key={subItem.path}
                to={subItem.path}
                className={`flex items-center px-4 py-2 text-sm text-gray-600 rounded-lg transition-colors ${
                  isSubActive
                    ? 'bg-primary text-white hover:bg-primary/90'
                    : 'hover:bg-gray-50 hover:text-primary'
                }`}
              >
                <subItem.icon className={`w-4 h-4 mr-3 ${isSubActive ? 'text-white' : ''}`} />
                {subItem.title}
              </Link>
            );
          })}
        </div>
      )}
    </div>
  );
}