import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { ServiceForm } from '@/components/admin/services/ServiceForm';
import { supabase } from '@/integrations/supabase/client';
import type { ServiceData } from '@/components/admin/services/serviceSchema';

export default function EditService() {
  const { id } = useParams<{ id: string }>();
  const isCreating = !id;

  const { data: service, isLoading } = useQuery({
    queryKey: ['service', id],
    queryFn: async () => {
      if (isCreating) return null;

      const { data, error } = await supabase
        .from('services')
        .select('*')
        .eq('id', id)
        .single();

      if (error) throw error;

      const benefits = Array.isArray(data.benefits) ? data.benefits : [];
      
      const serviceData: ServiceData = {
        ...data,
        benefits: benefits as string[],
        icon_name: (data.icon_name || 'code') as ServiceData['icon_name'],
      };

      return serviceData;
    },
    enabled: !isCreating,
  });

  if (isLoading && !isCreating) {
    return (
      <div className="p-8">
        <div className="animate-pulse space-y-4">
          <div className="h-8 bg-gray-200 w-1/4 rounded" />
          <div className="h-4 bg-gray-200 w-1/2 rounded" />
          <div className="space-y-2">
            <div className="h-4 bg-gray-200 rounded" />
            <div className="h-4 bg-gray-200 rounded" />
            <div className="h-4 bg-gray-200 rounded" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">
          {isCreating ? "Novo Serviço" : "Editar Serviço"}
        </h1>
        <p className="text-gray-600 mt-2">
          {isCreating 
            ? "Preencha os dados para criar um novo serviço"
            : "Faça as alterações necessárias no serviço"
          }
        </p>
      </div>

      <ServiceForm initialData={service} />
    </div>
  );
}