import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import { CategoryFormData } from "../categorySchema";

interface CategoryNameFieldProps {
  form: UseFormReturn<CategoryFormData>;
}

export function CategoryNameField({ form }: CategoryNameFieldProps) {
  return (
    <FormField
      control={form.control}
      name="name"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Nome</FormLabel>
          <FormControl>
            <Input placeholder="Digite o nome da categoria" {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}