import { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { supabase } from '@/integrations/supabase/client';
import { Button } from '@/components/ui/button';
import { Plus } from 'lucide-react';
import { toast } from 'sonner';
import { CreateUserForm } from '@/components/admin/users/CreateUserForm';
import { EditUserForm } from '@/components/admin/users/EditUserForm';
import { useAuth } from '@/hooks/useAuth';
import { useUsers, type Profile } from '@/hooks/useUsers';
import { UsersTable } from '@/components/admin/users/UsersTable';
import { useQuery } from '@tanstack/react-query';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";

export default function Users() {
  const { user } = useAuth();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<Profile | null>(null);
  const { data: profiles, refetch, isLoading, error } = useUsers();

  const { data: currentUserProfile } = useQuery({
    queryKey: ['profile', user?.id],
    queryFn: async () => {
      if (!user?.id) return null;
      
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profileError) {
        console.error('Error fetching current user profile:', profileError);
        throw profileError;
      }

      if (!profileData) {
        console.log('No profile found for current user');
        return null;
      }

      const { data: emailData, error: emailError } = await supabase
        .rpc('get_auth_users')
        .then(response => ({
          data: response.data?.find(e => e.id === user.id),
          error: response.error
        }));

      if (emailError) {
        console.error('Error fetching user email:', emailError);
        throw emailError;
      }
      
      return {
        id: profileData.id,
        full_name: profileData.full_name,
        role: profileData.role,
        avatar_url: profileData.avatar_url,
        email: emailData?.email || ''
      } as Profile;
    },
    enabled: !!user?.id
  });

  if (isLoading) {
    return (
      <div className="p-8">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  if (error) {
    console.error('Error loading users:', error);
    return (
      <div className="p-8">
        <div className="text-red-500">Erro ao carregar usuários. Por favor, tente novamente.</div>
      </div>
    );
  }

  const isAdmin = currentUserProfile?.role === 'admin';

  const handleDeleteUser = async (userId: string) => {
    if (!isAdmin && user?.id !== userId) {
      toast.error('Você não tem permissão para excluir este usuário');
      return;
    }

    try {
      console.log('Iniciando exclusão do usuário:', userId);

      // Primeiro excluir o perfil
      const { error: profileError } = await supabase
        .from('profiles')
        .delete()
        .eq('id', userId);

      if (profileError) {
        console.error('Erro ao excluir perfil:', profileError);
        throw profileError;
      }

      console.log('Perfil excluído com sucesso');

      // Depois excluir o usuário do auth usando RPC
      const { error: deleteError } = await supabase
        .rpc('delete_user', { user_id: userId });

      if (deleteError) {
        console.error('Erro ao excluir usuário:', deleteError);
        throw deleteError;
      }

      toast.success('Usuário excluído com sucesso');
      refetch();
    } catch (error: any) {
      console.error('Erro ao excluir usuário:', error);
      toast.error(error.message || 'Erro ao excluir usuário');
    }
  };

  const handleEditUser = (profile: Profile) => {
    if (!isAdmin && user?.id !== profile.id) {
      toast.error('Você não tem permissão para editar este usuário');
      return;
    }
    setSelectedUser(profile);
  };

  return (
    <div className="p-8">
      <Helmet>
        <title>Usuários - Painel Administrativo | Agência W3 Digital</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div className="flex justify-between items-center mb-8">
        <div>
          <h1 className="text-3xl font-bold">Usuários</h1>
          <p className="text-gray-600">Gerencie os usuários do sistema</p>
        </div>
        {isAdmin && (
          <Button onClick={() => setIsCreateModalOpen(true)}>
            <Plus className="mr-2" />
            Novo Usuário
          </Button>
        )}
      </div>

      <UsersTable
        profiles={profiles || []}
        currentUser={user}
        isAdmin={isAdmin}
        onEdit={handleEditUser}
        onDelete={handleDeleteUser}
      />

      <CreateUserForm 
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSuccess={refetch}
      />

      <Dialog open={!!selectedUser} onOpenChange={() => setSelectedUser(null)}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle>Editar Usuário</DialogTitle>
          </DialogHeader>
          {selectedUser && (
            <EditUserForm
              user={selectedUser}
              isAdmin={isAdmin}
              onSuccess={() => {
                setSelectedUser(null);
                refetch();
              }}
            />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}