import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';

export const GoogleTagsComponent = () => {
  const location = useLocation();
  const isAdminRoute = location.pathname.startsWith('/admin');

  // Se for rota admin, não renderiza as tags
  if (isAdminRoute) {
    return null;
  }

  const { data: headTags } = useQuery({
    queryKey: ['google-tags-head'],
    queryFn: async () => {
      console.log('Fetching head tags...');
      const { data, error } = await supabase
        .from('google_tags')
        .select('*')
        .eq('position', 'head')
        .maybeSingle(); // Alterado de single() para maybeSingle()
      
      if (error) {
        console.error('Error fetching head tags:', error);
        return null; // Retorna null ao invés de lançar erro
      }

      console.log('Head Tags:', data);
      return data;
    },
    retry: 3,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  const { data: bodyTags } = useQuery({
    queryKey: ['google-tags-body'],
    queryFn: async () => {
      console.log('Fetching body tags...');
      const { data, error } = await supabase
        .from('google_tags')
        .select('*')
        .eq('position', 'body')
        .maybeSingle(); // Alterado de single() para maybeSingle()
      
      if (error) {
        console.error('Error fetching body tags:', error);
        return null; // Retorna null ao invés de lançar erro
      }

      console.log('Body Tags:', data);
      return data;
    },
    retry: 3,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  useEffect(() => {
    // Injetar tags no head
    if (headTags?.code) {
      try {
        const headScript = document.getElementById('google-tag-manager-head');
        if (headScript) {
          headScript.remove();
        }
        
        const scriptElement = document.createElement('script');
        scriptElement.id = 'google-tag-manager-head';
        const cleanCode = headTags.code
          .replace('<!-- Google Tag Manager -->', '')
          .replace('<!-- End Google Tag Manager -->', '')
          .replace(/<\/?script>/g, '');
        
        scriptElement.textContent = cleanCode;
        document.head.appendChild(scriptElement);
        console.log('Head tag injected successfully');
      } catch (error) {
        console.error('Error injecting head tag:', error);
      }
    }

    // Injetar tags no body
    if (bodyTags?.code) {
      try {
        const bodyScript = document.getElementById('google-tag-manager-body');
        if (bodyScript) {
          bodyScript.remove();
        }
        
        const noscriptElement = document.createElement('noscript');
        noscriptElement.id = 'google-tag-manager-body';
        const cleanCode = bodyTags.code
          .replace('<!-- Google Tag Manager (noscript) -->', '')
          .replace('<!-- End Google Tag Manager (noscript) -->', '')
          .replace(/<\/?noscript>/g, '');
        
        noscriptElement.innerHTML = cleanCode;
        document.body.insertBefore(noscriptElement, document.body.firstChild);
        console.log('Body tag injected successfully');
      } catch (error) {
        console.error('Error injecting body tag:', error);
      }
    }
  }, [headTags, bodyTags]);

  return null;
};