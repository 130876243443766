import { FormField, FormItem, FormLabel, FormControl, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { UseFormReturn } from 'react-hook-form';
import { SmtpFormData } from './types';

interface SmtpEmailFieldsProps {
  form: UseFormReturn<SmtpFormData>;
}

export function SmtpEmailFields({ form }: SmtpEmailFieldsProps) {
  return (
    <>
      <FormField
        control={form.control}
        name="service_id"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Service ID</FormLabel>
            <FormControl>
              <Input placeholder="service_xxxxxxx" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="template_id"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Template ID</FormLabel>
            <FormControl>
              <Input placeholder="template_xxxxxxx" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />

      <FormField
        control={form.control}
        name="public_key"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Public Key</FormLabel>
            <FormControl>
              <Input placeholder="XXXXXXXXXXXXXXXX" {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
    </>
  );
}