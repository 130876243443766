import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { ImageUpload } from "@/components/blog/ImageUpload";
import { UseFormReturn } from "react-hook-form";
import { ServiceFormData } from "../serviceSchema";

interface ServiceImageFieldProps {
  form: UseFormReturn<ServiceFormData>;
}

export function ServiceImageField({ form }: ServiceImageFieldProps) {
  return (
    <FormField
      control={form.control}
      name="image_url"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Imagem</FormLabel>
          <FormControl>
            <ImageUpload
              onImageUploaded={field.onChange}
              currentImage={field.value}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}