import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Info } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';

const siteInfoSchema = z.object({
  address: z.string().min(1, 'Endereço é obrigatório'),
  email: z.string().email('E-mail inválido'),
  phone: z.string().min(1, 'Telefone é obrigatório'),
  whatsapp: z.string().min(1, 'WhatsApp é obrigatório'),
  google_maps_code: z.string().optional(),
});

type SiteInfo = z.infer<typeof siteInfoSchema>;

export default function SiteInfo() {
  const queryClient = useQueryClient();

  const form = useForm<SiteInfo>({
    resolver: zodResolver(siteInfoSchema),
    defaultValues: {
      address: '',
      email: '',
      phone: '',
      whatsapp: '',
      google_maps_code: '',
    },
  });

  const { data: siteInfo, isLoading } = useQuery({
    queryKey: ['site-info'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('site_info')
        .select('*')
        .single();

      if (error) throw error;
      
      // Set form values when data is loaded
      if (data) {
        form.reset(data);
      }
      
      return data;
    },
  });

  const mutation = useMutation({
    mutationFn: async (values: SiteInfo) => {
      // Get the existing record ID
      const { data: existingData } = await supabase
        .from('site_info')
        .select('id')
        .single();

      const { data, error } = await supabase
        .from('site_info')
        .upsert({
          id: existingData?.id, // Use existing ID if available
          ...values,
          updated_at: new Date().toISOString(),
        })
        .select()
        .single();

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['site-info'] });
      toast.success('Informações salvas com sucesso!');
    },
    onError: (error) => {
      console.error('Erro ao salvar informações:', error);
      toast.error('Erro ao salvar informações');
    },
  });

  if (isLoading) {
    return (
      <div className="p-8">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">Informações do Site</h1>
        <p className="text-gray-600">Gerencie as informações de contato e localização</p>
      </div>

      <div className="max-w-2xl bg-white p-6 rounded-lg shadow-sm border border-gray-200">
        <Form {...form}>
          <form onSubmit={form.handleSubmit((data) => mutation.mutate(data))} className="space-y-6">
            <FormField
              control={form.control}
              name="address"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Endereço</FormLabel>
                  <FormControl>
                    <Input placeholder="Rua, número, bairro, cidade - UF" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="email"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>E-mail</FormLabel>
                  <FormControl>
                    <Input type="email" placeholder="contato@exemplo.com" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="phone"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Telefone</FormLabel>
                  <FormControl>
                    <Input placeholder="(00) 0000-0000" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="whatsapp"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>WhatsApp</FormLabel>
                  <FormControl>
                    <Input placeholder="(00) 00000-0000" {...field} />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <FormField
              control={form.control}
              name="google_maps_code"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Código do Google Maps</FormLabel>
                  <FormControl>
                    <Textarea
                      placeholder="Cole o código de incorporação do Google Maps"
                      className="font-mono"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />

            <Button type="submit" disabled={mutation.isPending}>
              <Info className="w-4 h-4 mr-2" />
              Salvar Informações
            </Button>
          </form>
        </Form>
      </div>
    </div>
  );
}