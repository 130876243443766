import { useLocation } from 'react-router-dom';
import { MenuItem } from './MenuItem';
import type { MenuItemType } from './MenuItem';

interface AdminSidebarProps {
  isSidebarOpen: boolean;
  isBlogMenuOpen: boolean;
  setIsBlogMenuOpen: (isOpen: boolean) => void;
  isSettingsMenuOpen: boolean;
  setIsSettingsMenuOpen: (isOpen: boolean) => void;
  handleLogout: () => void;
  menuItems: MenuItemType[];
}

export function AdminSidebar({ 
  isSidebarOpen,
  isBlogMenuOpen,
  setIsBlogMenuOpen,
  isSettingsMenuOpen,
  setIsSettingsMenuOpen,
  handleLogout,
  menuItems 
}: AdminSidebarProps) {
  const location = useLocation();

  return (
    <aside className={`fixed left-0 top-0 z-40 h-screen w-64 border-r border-gray-200 bg-white transition-transform lg:translate-x-0 ${
      isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
    }`}>
      <div className="flex h-full flex-col justify-between">
        <div>
          {/* Logo */}
          <div className="flex h-16 items-center justify-center border-b border-gray-200">
            <img src="/logomarca.png" alt="Agência W3" className="h-8" />
          </div>

          {/* Menu */}
          <nav className="space-y-1 p-4">
            {menuItems.map((item) => (
              <MenuItem
                key={item.path}
                item={item}
                isBlogMenuOpen={isBlogMenuOpen}
                setIsBlogMenuOpen={setIsBlogMenuOpen}
                isSettingsMenuOpen={isSettingsMenuOpen}
                setIsSettingsMenuOpen={setIsSettingsMenuOpen}
              />
            ))}
          </nav>
        </div>

        {/* Logout */}
        <div className="border-t border-gray-200 p-4">
          <button
            onClick={handleLogout}
            className="flex w-full items-center rounded-lg px-3 py-2 text-sm text-gray-600 hover:bg-gray-100"
          >
            <span className="mr-2">
              <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                <polyline points="16 17 21 12 16 7" />
                <line x1="21" y1="12" x2="9" y2="12" />
              </svg>
            </span>
            Sair
          </button>
        </div>
      </div>
    </aside>
  );
}