import { BaseLayout } from '../components/layouts/BaseLayout';
import { Hero } from '../components/Hero';
import { Services } from '../components/Services';

const Index = () => {
  return (
    <BaseLayout>
      <div className="pt-24"> {/* Aumentado o padding top */}
        <Hero />
        <Services />
      </div>
    </BaseLayout>
  );
};

export default Index;