import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import { PostFormData } from "../postSchema";

interface PostTitleFieldProps {
  form: UseFormReturn<PostFormData>;
}

export function PostTitleField({ form }: PostTitleFieldProps) {
  return (
    <FormField
      control={form.control}
      name="title"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Título</FormLabel>
          <FormControl>
            <Input placeholder="Digite o título do post" {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}