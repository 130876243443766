import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Plus, Share2, Trash2 } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';

const socialNetworkSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  url: z.string().url('URL inválida'),
});

type SocialNetwork = z.infer<typeof socialNetworkSchema> & { id: string };

export default function SocialNetworks() {
  const queryClient = useQueryClient();
  const form = useForm<z.infer<typeof socialNetworkSchema>>({
    resolver: zodResolver(socialNetworkSchema),
    defaultValues: {
      name: '',
      url: '',
    },
  });

  const { data: socialNetworks, isLoading } = useQuery({
    queryKey: ['social-networks'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('social_networks')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as SocialNetwork[];
    },
  });

  const addMutation = useMutation({
    mutationFn: async (values: z.infer<typeof socialNetworkSchema>) => {
      const { data, error } = await supabase
        .from('social_networks')
        .insert([values]);

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['social-networks'] });
      form.reset();
      toast.success('Rede social adicionada com sucesso!');
    },
    onError: (error) => {
      console.error('Erro ao adicionar rede social:', error);
      toast.error('Erro ao adicionar rede social');
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      const { error } = await supabase
        .from('social_networks')
        .delete()
        .eq('id', id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['social-networks'] });
      toast.success('Rede social removida com sucesso!');
    },
    onError: (error) => {
      console.error('Erro ao remover rede social:', error);
      toast.error('Erro ao remover rede social');
    },
  });

  if (isLoading) {
    return (
      <div className="p-8">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">Redes Sociais</h1>
        <p className="text-gray-600">Gerencie as redes sociais do site</p>
      </div>

      <div className="grid gap-8 lg:grid-cols-2">
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <h2 className="text-xl font-semibold mb-4">Adicionar Rede Social</h2>
          <Form {...form}>
            <form onSubmit={form.handleSubmit((data) => addMutation.mutate(data))} className="space-y-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nome da Rede Social</FormLabel>
                    <FormControl>
                      <Input placeholder="Ex: Instagram" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="url"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>URL do Perfil</FormLabel>
                    <FormControl>
                      <Input placeholder="https://..." {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit" disabled={addMutation.isPending}>
                <Plus className="w-4 h-4 mr-2" />
                Adicionar Rede Social
              </Button>
            </form>
          </Form>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <h2 className="text-xl font-semibold mb-4">Redes Sociais Cadastradas</h2>
          <div className="space-y-4">
            {socialNetworks?.length === 0 ? (
              <p className="text-gray-500">Nenhuma rede social cadastrada</p>
            ) : (
              socialNetworks?.map((network) => (
                <div
                  key={network.id}
                  className="p-4 border border-gray-200 rounded-lg flex items-center justify-between"
                >
                  <div className="flex items-center space-x-3">
                    <Share2 className="w-5 h-5 text-primary" />
                    <div>
                      <span className="font-medium">{network.name}</span>
                      <a
                        href={network.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block text-sm text-gray-500 hover:text-primary"
                      >
                        {network.url}
                      </a>
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => deleteMutation.mutate(network.id)}
                    className="text-red-500 hover:text-red-600 hover:bg-red-50"
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}