import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { supabase } from '@/integrations/supabase/client';
import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { toast } from 'sonner';
import type { AuthChangeEvent } from '@supabase/supabase-js';

export default function Login() {
  console.log('Login component rendered');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let isSubscribed = true;

    const checkSession = async () => {
      try {
        console.log('Verificando sessão...');
        const { data: { session }, error } = await supabase.auth.getSession();
        
        if (error) {
          console.error('Erro ao verificar sessão:', error);
          toast.error('Erro ao verificar sessão');
          if (isSubscribed) setIsLoading(false);
          return;
        }

        console.log('Sessão atual:', session);

        if (session?.user) {
          console.log('Usuário encontrado, redirecionando...');
          navigate('/admin/dashboard');
        }
        if (isSubscribed) setIsLoading(false);
      } catch (error) {
        console.error('Erro ao verificar autenticação:', error);
        if (isSubscribed) setIsLoading(false);
      }
    };

    checkSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange(async (event: AuthChangeEvent, session) => {
      console.log('Evento de autenticação:', event);
      console.log('Sessão:', session);

      if (!isSubscribed) return;

      if (event === 'SIGNED_IN' && session?.user) {
        navigate('/admin/dashboard');
      } else if (event === 'SIGNED_OUT') {
        console.log('Usuário deslogado');
      }
    });

    return () => {
      isSubscribed = false;
      subscription.unsubscribe();
    };
  }, [navigate]);

  if (isLoading) {
    return <div className="min-h-screen flex items-center justify-center">
      <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
    </div>;
  }

  return (
    <>
      <Helmet>
        <title>Login - Painel Administrativo | Agência W3 Digital</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>

      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="max-w-md w-full space-y-8 p-8 bg-white rounded-xl shadow-lg">
          <div className="text-center">
            <img
              src="/logomarca.png"
              alt="Agência W3 Digital"
              className="mx-auto h-12 w-auto"
            />
            <h2 className="mt-6 text-3xl font-bold text-gray-900">
              Painel Administrativo
            </h2>
            <p className="mt-2 text-sm text-gray-600">
              Faça login para acessar o painel
            </p>
          </div>

          <Auth
            supabaseClient={supabase}
            appearance={{
              theme: ThemeSupa,
              variables: {
                default: {
                  colors: {
                    brand: '#9b87f5',
                    brandAccent: '#7E69AB',
                  },
                },
              },
              className: {
                message: 'text-red-500',
              },
            }}
            localization={{
              variables: {
                sign_in: {
                  email_label: 'Email',
                  password_label: 'Senha',
                  button_label: 'Entrar',
                  loading_button_label: 'Entrando...',
                  email_input_placeholder: 'Seu email',
                  password_input_placeholder: 'Sua senha',
                },
              },
            }}
            providers={[]}
            view="sign_in"
            showLinks={false}
            redirectTo={`${window.location.origin}/admin/dashboard`}
          />
        </div>
      </div>
    </>
  );
}