import { BaseLayout } from '../components/layouts/BaseLayout';
import { ContactForm } from '../components/contact/ContactForm';
import { ContactInfo } from '../components/contact/ContactInfo';
import { ContactMap } from '../components/contact/ContactMap';
import { Helmet } from 'react-helmet-async';

const Contact = () => {
  // Schema.org JSON-LD para a página de contato
  const contactSchema = {
    "@context": "https://schema.org",
    "@type": "ContactPage",
    "name": "Contato - Agência W3 Digital",
    "description": "Entre em contato com a Agência W3 Digital para discutir seu projeto ou tirar dúvidas sobre nossos serviços.",
    "mainEntity": {
      "@type": "Organization",
      "name": "Agência W3 Digital",
      "address": {
        "@type": "PostalAddress",
        "streetAddress": "Rua Walter Hubacher, 1808",
        "addressLocality": "Nova Andradina",
        "addressRegion": "MS",
        "postalCode": "79750-000",
        "addressCountry": "BR"
      },
      "telephone": "(67) 3441-9114",
      "email": "atendimento@agenciaw3.digital",
      "url": "https://agenciaw3.digital"
    }
  };

  return (
    <BaseLayout>
      <Helmet>
        <title>Contato - Agência W3 Digital | Fale Conosco</title>
        <meta 
          name="description" 
          content="Entre em contato com a Agência W3 Digital. Estamos prontos para ajudar você a transformar suas ideias em realidade digital." 
        />
        <meta 
          name="keywords" 
          content="contato agência w3, desenvolvimento web, marketing digital, atendimento, orçamento" 
        />
        <link rel="canonical" href="https://agenciaw3.digital/contato" />
        <script type="application/ld+json">
          {JSON.stringify(contactSchema)}
        </script>
      </Helmet>

      <div className="min-h-screen pt-28">
        {/* Hero Section */}
        <div className="text-center mb-16 animate-fade-up">
          <h1 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">
            Fale Conosco
          </h1>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Estamos prontos para ajudar você a transformar suas ideias em realidade.
          </p>
        </div>

        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-2 gap-12 mb-16">
            {/* Contact Form */}
            <div className="space-y-6">
              <ContactForm />
            </div>

            {/* Contact Info and Map */}
            <div className="space-y-8">
              <ContactInfo />
              <ContactMap />
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default Contact;