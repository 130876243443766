import { useQuery } from '@tanstack/react-query';
import { Code, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import * as Icons from 'lucide-react';

export const ServicesListHome = () => {
  const { data, isLoading, error } = useQuery({
    queryKey: ['services-home'],
    queryFn: async () => {
      const { data: services, error } = await supabase
        .from('services')
        .select('*')
        .order('title')
        .limit(8);
      
      if (error) {
        console.error('Error fetching services:', error);
        throw error;
      }

      return services;
    }
  });

  const getIconComponent = (iconName: string | null) => {
    if (!iconName) {
      return Code;
    }
    
    const formattedIconName = iconName.charAt(0).toUpperCase() + iconName.slice(1);
    const IconComponent = (Icons as any)[formattedIconName];
    
    if (!IconComponent) {
      return Code;
    }
    
    return IconComponent;
  };

  if (isLoading) {
    return (
      <section className="py-20 bg-white">
        <div className="container mx-auto px-4">
          <div className="animate-pulse space-y-4">
            {[...Array(8)].map((_, i) => (
              <div key={i} className="h-48 bg-gray-200 rounded-xl" />
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error) {
    return (
      <div className="text-center py-20">
        <p className="text-red-500">Erro ao carregar serviços. Por favor, tente novamente mais tarde.</p>
      </div>
    );
  }

  if (!data?.length) {
    return (
      <div className="text-center py-20">
        <p className="text-gray-600">Nenhum serviço disponível no momento.</p>
      </div>
    );
  }

  return (
    <section className="py-20 bg-white">
      <div className="container mx-auto px-4">
        <div className="text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">Nossos Serviços</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Oferecemos soluções completas em tecnologia para impulsionar seu negócio
          </p>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
          {data.map((service) => {
            const IconComponent = getIconComponent(service.icon_name);
            
            return (
              <Link
                key={service.id}
                to={`/servicos/${service.slug}`}
                className="group"
              >
                <div className="p-6 rounded-xl border border-gray-100 hover:border-primary/20 transition-all duration-200 h-full hover:-translate-y-1">
                  <IconComponent
                    size={40}
                    className="text-primary mb-4 group-hover:text-accent transition-colors duration-200"
                  />
                  <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
                  <p className="text-gray-600 mb-4">{service.description}</p>
                  <span className="text-primary group-hover:text-accent inline-flex items-center">
                    Saiba mais
                    <ArrowRight className="ml-2" size={16} />
                  </span>
                </div>
              </Link>
            );
          })}
        </div>

        <div className="mt-24 py-16 px-4 sm:px-12 rounded-3xl bg-gradient-to-r from-primary/5 via-primary/10 to-accent/5">
          <div className="text-center">
            <h2 className="text-3xl md:text-4xl font-bold mb-4">
              Pronto para transformar seu negócio?
            </h2>
            <p className="text-gray-600 max-w-2xl mx-auto mb-8">
              Entre em contato conosco para discutir como podemos ajudar sua empresa a alcançar seus objetivos digitais.
            </p>
            <Link
              to="/contato"
              className="inline-flex items-center px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary-dark transition-colors duration-200"
            >
              Fale Conosco
              <ArrowRight className="ml-2" size={16} />
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};