import { Input } from '@/components/ui/input';
import { FormControl, FormDescription } from '@/components/ui/form';
import { 
  Code, 
  Database, 
  Globe, 
  Brain, 
  Mail, 
  Cloud, 
  Server, 
  Settings,
  Bell,
  Calendar,
  Camera,
  ChevronRight,
  Clock,
  Coffee,
  Computer,
  CreditCard,
  Download,
  File,
  FileText,
  Filter,
  Flag,
  Folder,
  Heart,
  Home,
  Image,
  Key,
  Link,
  Lock,
  Map,
  MessageCircle,
  Music,
  Package,
  Phone,
  Search,
  Send,
  Share,
  Shield,
  ShoppingCart,
  Star,
  Sun,
  Wrench,
  Trash,
  User,
  Video,
  Wifi,
  Zap
} from 'lucide-react';
import { useState } from 'react';

interface IconSelectProps {
  value: string;
  onChange: (value: string) => void;
}

const availableIcons = {
  code: Code,
  database: Database,
  globe: Globe,
  brain: Brain,
  mail: Mail,
  cloud: Cloud,
  server: Server,
  settings: Settings,
  bell: Bell,
  calendar: Calendar,
  camera: Camera,
  chevronRight: ChevronRight,
  clock: Clock,
  coffee: Coffee,
  computer: Computer,
  creditCard: CreditCard,
  download: Download,
  file: File,
  fileText: FileText,
  filter: Filter,
  flag: Flag,
  folder: Folder,
  heart: Heart,
  home: Home,
  image: Image,
  key: Key,
  link: Link,
  lock: Lock,
  map: Map,
  messageCircle: MessageCircle,
  music: Music,
  package: Package,
  phone: Phone,
  search: Search,
  send: Send,
  share: Share,
  shield: Shield,
  shoppingCart: ShoppingCart,
  star: Star,
  sun: Sun,
  wrench: Wrench,
  trash: Trash,
  user: User,
  video: Video,
  wifi: Wifi,
  zap: Zap
};

export function IconSelect({ value, onChange }: IconSelectProps) {
  const [error, setError] = useState<string | null>(null);
  const IconComponent = availableIcons[value as keyof typeof availableIcons];

  const handleChange = (newValue: string) => {
    setError(null);
    if (newValue === '') {
      onChange(newValue);
      return;
    }
    
    if (availableIcons[newValue as keyof typeof availableIcons]) {
      onChange(newValue);
    } else {
      setError('Ícone não encontrado. Visite https://lucide.dev/icons para ver a lista completa de ícones disponíveis.');
    }
  };

  return (
    <div className="space-y-2">
      <div className="flex items-center gap-2">
        <FormControl>
          <Input
            placeholder="Digite o nome do ícone (ex: bell, database)"
            value={value}
            onChange={(e) => handleChange(e.target.value)}
          />
        </FormControl>
        {IconComponent && (
          <div className="w-10 h-10 flex items-center justify-center">
            <IconComponent className="w-6 h-6" />
          </div>
        )}
      </div>
      {error && (
        <FormDescription className="text-red-500 text-sm">
          {error}
        </FormDescription>
      )}
      <FormDescription>
        Visite <a href="https://lucide.dev/icons" target="_blank" rel="noopener noreferrer" className="text-primary hover:underline">lucide.dev/icons</a> para ver todos os ícones disponíveis
      </FormDescription>
    </div>
  );
}