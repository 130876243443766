import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

interface BlogPostNavigationProps {
  title: string;
}

export const BlogPostNavigation = ({ title }: BlogPostNavigationProps) => {
  return (
    <>
      <Breadcrumb className="mb-8">
        <BreadcrumbList>
          <BreadcrumbItem>
            <Link to="/" className="transition-colors hover:text-foreground">Início</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <Link to="/blog" className="transition-colors hover:text-foreground">Blog</Link>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
          <BreadcrumbItem>
            <BreadcrumbPage>{title}</BreadcrumbPage>
          </BreadcrumbItem>
        </BreadcrumbList>
      </Breadcrumb>

      <Link
        to="/blog"
        className="inline-flex items-center text-primary hover:text-primary-dark mb-8"
      >
        <ArrowLeft size={20} className="mr-2" />
        Voltar para o blog
      </Link>
    </>
  );
};