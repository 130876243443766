import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { UseFormReturn } from "react-hook-form";
import { ServiceFormData } from "../serviceSchema";

interface ServiceTitleFieldProps {
  form: UseFormReturn<ServiceFormData>;
}

export function ServiceTitleField({ form }: ServiceTitleFieldProps) {
  return (
    <FormField
      control={form.control}
      name="title"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Título</FormLabel>
          <FormControl>
            <Input placeholder="Digite o título do serviço" {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}