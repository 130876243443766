import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export const Hero = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gradient-to-b from-white to-gray-50">
      <div className="container mx-auto px-4">
        <div className="text-center space-y-10 animate-fade-up">
          <h1 className="text-4xl md:text-6xl font-bold leading-relaxed">
            Transformando Ideias em <span className="gradient-text">Realidade Digital</span>
          </h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Somos especialistas em desenvolvimento de aplicações web e mobile,
            criando soluções inovadoras que impulsionam o seu negócio.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <Link to="/contato" className="btn-primary inline-flex items-center">
              Fale Conosco
              <ArrowRight className="ml-2" size={20} />
            </Link>
            <Link to="/servicos" className="btn-secondary inline-flex items-center">
              Nossos Serviços
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};