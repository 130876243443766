import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Mail } from 'lucide-react';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { toast } from '@/components/ui/use-toast';
import emailjs from '@emailjs/browser';
import { Form } from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { SmtpEmailFields } from '@/components/admin/settings/smtp/SmtpEmailFields';
import { smtpSchema, type SmtpFormData, type SmtpSettings } from '@/components/admin/settings/smtp/types';

export default function SmtpSettings() {
  const [isTesting, setIsTesting] = useState(false);
  const queryClient = useQueryClient();

  const form = useForm<SmtpFormData>({
    resolver: zodResolver(smtpSchema),
    defaultValues: {
      service_id: '',
      template_id: '',
      public_key: '',
    },
  });

  const { data: smtpSettings, isLoading } = useQuery({
    queryKey: ['smtp-settings'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('smtp_settings')
        .select('id, service_id, template_id, public_key')
        .order('updated_at', { ascending: false })
        .limit(1)
        .single();

      if (error) throw error;
      return data as SmtpSettings;
    },
  });

  React.useEffect(() => {
    if (smtpSettings) {
      form.reset({
        service_id: smtpSettings.service_id,
        template_id: smtpSettings.template_id,
        public_key: smtpSettings.public_key,
      });
    }
  }, [smtpSettings, form]);

  const mutation = useMutation({
    mutationFn: async (values: SmtpFormData) => {
      const settingsData: SmtpSettings = {
        service_id: values.service_id,
        template_id: values.template_id,
        public_key: values.public_key,
      };

      if (smtpSettings?.id) {
        const { data, error } = await supabase
          .from('smtp_settings')
          .update(settingsData)
          .eq('id', smtpSettings.id)
          .select()
          .single();

        if (error) throw error;
        return data;
      } else {
        const { data, error } = await supabase
          .from('smtp_settings')
          .insert([settingsData])
          .select()
          .single();

        if (error) throw error;
        return data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['smtp-settings'] });
      toast({
        title: "Sucesso",
        description: "Configurações salvas com sucesso!",
      });
    },
    onError: (error) => {
      console.error('Erro ao salvar configurações:', error);
      toast({
        variant: "destructive",
        title: "Erro",
        description: "Erro ao salvar configurações",
      });
    },
  });

  const testConnection = async () => {
    setIsTesting(true);
    try {
      // Inicializa o EmailJS com a chave pública
      emailjs.init(form.getValues('public_key'));

      const templateParams = {
        to_email: 'atendimento@agenciaw3.digital', // Email de destino para teste
        subject: 'Teste de Conexão EmailJS',
        message: 'Este é um e-mail de teste enviado através do EmailJS.',
      };

      const response = await emailjs.send(
        form.getValues('service_id'),
        form.getValues('template_id'),
        templateParams
      );

      if (response.status === 200) {
        toast({
          title: "Sucesso",
          description: "E-mail de teste enviado com sucesso!",
        });
      } else {
        toast({
          variant: "destructive",
          title: "Erro",
          description: "Erro ao enviar e-mail de teste",
        });
      }
    } catch (error: any) {
      console.error('Erro ao testar conexão:', error);
      toast({
        variant: "destructive",
        title: "Erro",
        description: `Erro ao testar conexão: ${error.text || error.message}`,
      });
    } finally {
      setIsTesting(false);
    }
  };

  if (isLoading) {
    return (
      <div className="p-8">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">Configurações de E-mail</h1>
        <p className="text-gray-600">Configure as credenciais do EmailJS</p>
      </div>

      <div className="max-w-2xl bg-white p-6 rounded-lg shadow-sm border border-gray-200">
        <Form {...form}>
          <form onSubmit={form.handleSubmit((data) => mutation.mutate(data))} className="space-y-6">
            <SmtpEmailFields form={form} />

            <div className="flex gap-4">
              <Button type="submit" disabled={mutation.isPending}>
                <Mail className="w-4 h-4 mr-2" />
                Salvar Configurações
              </Button>
              <Button
                type="button"
                variant="outline"
                onClick={testConnection}
                disabled={isTesting}
              >
                {isTesting ? 'Testando...' : 'Testar Conexão'}
              </Button>
            </div>
          </form>
        </Form>
      </div>
    </div>
  );
}