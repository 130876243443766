import { BaseLayout } from '../components/layouts/BaseLayout';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { Helmet } from 'react-helmet-async';

const NotFound = () => {
  return (
    <BaseLayout>
      <Helmet>
        <title>Página Não Encontrada | Agência W3 Digital</title>
        <meta 
          name="description" 
          content="A página que você está procurando não foi encontrada. Volte para a página inicial ou explore nossos serviços." 
        />
        <meta name="robots" content="noindex, follow" />
      </Helmet>

      <div className="min-h-screen pt-28 pb-16">
        <div className="container mx-auto px-4">
          <div className="max-w-2xl mx-auto text-center">
            <h1 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">
              404 - Página Não Encontrada
            </h1>
            <p className="text-xl text-gray-600 mb-8">
              Desculpe, a página que você está procurando não existe ou foi movida.
            </p>
            
            <div className="space-y-4">
              <Link 
                to="/" 
                className="btn-primary inline-flex items-center"
                aria-label="Voltar para a página inicial"
              >
                <ArrowLeft className="mr-2" size={20} aria-hidden="true" />
                Voltar para a página inicial
              </Link>
              
              <div className="mt-8 text-gray-600">
                <p>
                  Você também pode:
                </p>
                <ul className="mt-4 space-y-2">
                  <li>
                    <Link 
                      to="/servicos" 
                      className="text-primary hover:text-primary-dark transition-colors"
                      aria-label="Explorar nossos serviços"
                    >
                      Explorar nossos serviços
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/blog" 
                      className="text-primary hover:text-primary-dark transition-colors"
                      aria-label="Visitar nosso blog"
                    >
                      Visitar nosso blog
                    </Link>
                  </li>
                  <li>
                    <Link 
                      to="/contato" 
                      className="text-primary hover:text-primary-dark transition-colors"
                      aria-label="Entrar em contato conosco"
                    >
                      Entrar em contato conosco
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BaseLayout>
  );
};

export default NotFound;