import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Textarea } from "@/components/ui/textarea";
import { UseFormReturn } from "react-hook-form";
import { ServiceFormData } from "../serviceSchema";

interface ServiceDescriptionFieldProps {
  form: UseFormReturn<ServiceFormData>;
}

export function ServiceDescriptionField({ form }: ServiceDescriptionFieldProps) {
  return (
    <FormField
      control={form.control}
      name="description"
      render={({ field }) => (
        <FormItem>
          <FormLabel>Descrição Breve</FormLabel>
          <FormControl>
            <Textarea 
              placeholder="Digite uma breve descrição do serviço" 
              {...field} 
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
}