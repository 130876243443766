import { BaseLayout } from '../components/layouts/BaseLayout';
import { Helmet } from 'react-helmet-async';
import { ServicesList } from '@/components/services/ServicesList';

const Services = () => {
  return (
    <BaseLayout>
      <Helmet>
        <title>Serviços - Agência W3</title>
        <meta name="description" content="Conheça nossos serviços de tecnologia e desenvolvimento web" />
      </Helmet>
      <div className="pt-24">
        <ServicesList />
      </div>
    </BaseLayout>
  );
};

export default Services;