import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Form } from '@/components/ui/form';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { useAuth } from '@/hooks/useAuth';
import { PostContentField } from './PostContentField';
import { postFormSchema, type PostFormData, type PostData } from './postSchema';
import { PostCategoryField } from './form/PostCategoryField';
import { PostTitleField } from './form/PostTitleField';
import { PostExcerptField } from './form/PostExcerptField';
import { PostImageField } from './form/PostImageField';
import { PostPublishField } from './form/PostPublishField';

interface PostFormProps {
  initialData?: PostData;
}

export function PostForm({ initialData }: PostFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const form = useForm<PostFormData>({
    resolver: zodResolver(postFormSchema),
    defaultValues: initialData ? {
      title: initialData.title,
      excerpt: initialData.excerpt,
      content: initialData.content,
      published: initialData.published,
      published_at: initialData.published_at ? new Date(initialData.published_at) : null,
      category_id: initialData.category_id,
      image_url: initialData.image_url,
    } : {
      title: '',
      excerpt: '',
      content: '',
      published: false,
      published_at: null,
      category_id: null,
      image_url: '',
    },
  });

  const onSubmit = async (data: PostFormData) => {
    if (!user?.id) {
      toast.error("Você precisa estar logado para realizar esta ação.");
      return;
    }

    try {
      setIsLoading(true);
      
      // Gerar um slug básico para satisfazer a constraint
      const baseSlug = data.title.toLowerCase().replace(/[^a-z0-9]+/g, '-');
      
      const postData = {
        title: data.title,
        excerpt: data.excerpt,
        content: data.content,
        published: data.published,
        published_at: data.published ? new Date().toISOString() : null,
        category_id: data.category_id,
        image_url: data.image_url,
        created_by: user.id,
        updated_by: user.id,
        slug: baseSlug, // Adicionar o slug
      };

      const { error } = initialData?.id
        ? await supabase
            .from('posts')
            .update(postData)
            .eq('id', initialData.id)
        : await supabase
            .from('posts')
            .insert(postData);

      if (error) throw error;

      toast.success(initialData ? "Post atualizado com sucesso!" : "Post cadastrado com sucesso!");
      navigate('/admin/blog');
      
    } catch (error) {
      console.error('Erro ao salvar post:', error);
      toast.error("Ocorreu um erro ao salvar o post.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <PostCategoryField form={form} />
        <PostTitleField form={form} />
        <PostExcerptField form={form} />
        <PostImageField form={form} />
        <PostContentField form={form} />
        <PostPublishField form={form} />

        <div className="flex justify-end">
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Salvando..." : (initialData ? "Atualizar" : "Cadastrar")}
          </Button>
        </div>
      </form>
    </Form>
  );
}