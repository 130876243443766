import React from 'react';
import { Check } from 'lucide-react';

interface ServiceBenefitsProps {
  benefits: string[];
}

export const ServiceBenefits = ({ benefits }: ServiceBenefitsProps) => {
  return (
    <div className="bg-gray-50 rounded-xl p-8 mb-12">
      <h2 className="text-2xl font-bold mb-6">Benefícios</h2>
      <ul className="space-y-4">
        {benefits.map((benefit, index) => (
          <li key={index} className="flex items-start">
            <Check className="text-primary mt-1 mr-2 flex-shrink-0" size={20} />
            <span>{benefit}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};