import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { supabase } from '@/integrations/supabase/client';
import {
  LayoutDashboard,
  Settings,
  Users,
  FileText,
  Newspaper,
  FolderPlus,
  FilePlus,
  Mail,
  Tag,
  Info,
  Share2
} from 'lucide-react';
import { toast } from 'sonner';
import { AdminHeader } from './admin/AdminHeader';
import { AdminSidebar } from './admin/AdminSidebar';
import { AdminFooter } from './admin/AdminFooter';
import type { MenuItemType } from './admin/MenuItem';

interface AdminLayoutProps {
  children: React.ReactNode;
}

export function AdminLayout({ children }: AdminLayoutProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [userFullName, setUserFullName] = useState<string | null>(null);
  const [isBlogMenuOpen, setIsBlogMenuOpen] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);

  useEffect(() => {
    if (location.pathname.includes('/admin/blog') || location.pathname.includes('/admin/categories')) {
      setIsBlogMenuOpen(true);
    }
    if (location.pathname.includes('/admin/settings')) {
      setIsSettingsMenuOpen(true);
    }
  }, [location.pathname]);

  useEffect(() => {
    const getProfile = async () => {
      const { data: { session } } = await supabase.auth.getSession();
      if (session?.user) {
        const { data: profile } = await supabase
          .from('profiles')
          .select('full_name')
          .eq('id', session.user.id)
          .single();
        
        if (profile) {
          setUserFullName(profile.full_name);
        }
      }
    };

    getProfile();
  }, []);

  const handleLogout = async () => {
    try {
      console.log('Iniciando processo de logout...');
      
      localStorage.removeItem('supabase.auth.token');
      
      const { data: { session } } = await supabase.auth.getSession();
      console.log('Sessão atual:', session);
      
      if (session) {
        const { error } = await supabase.auth.signOut({
          scope: 'local'
        });
        
        if (error) {
          console.error('Erro ao fazer logout:', error);
          throw error;
        }
      }
      
      setUserFullName(null);
      
      console.log('Logout realizado com sucesso');
      toast.success('Logout realizado com sucesso');
      
    } catch (error) {
      console.error('Erro durante logout:', error);
      toast.error('Erro ao fazer logout');
    } finally {
      navigate('/', { replace: true });
    }
  };

  const menuItems: MenuItemType[] = [
    {
      title: 'Dashboard',
      icon: LayoutDashboard,
      path: '/admin/dashboard'
    },
    {
      title: 'Configurações',
      icon: Settings,
      path: '/admin/settings',
      subItems: [
        {
          title: 'SMTP',
          icon: Mail,
          path: '/admin/settings/smtp'
        },
        {
          title: 'Footer e Header',
          icon: Tag,
          path: '/admin/settings/google-tags'
        },
        {
          title: 'Informações do Site',
          icon: Info,
          path: '/admin/settings/site-info'
        },
        {
          title: 'Redes Sociais',
          icon: Share2,
          path: '/admin/settings/social-networks'
        }
      ]
    },
    {
      title: 'Usuários',
      icon: Users,
      path: '/admin/users'
    },
    {
      title: 'Serviços',
      icon: FileText,
      path: '/admin/services'
    },
    {
      title: 'Blog',
      icon: Newspaper,
      path: '/admin/blog',
      subItems: [
        {
          title: 'Listar Posts',
          icon: FilePlus,
          path: '/admin/blog'
        },
        {
          title: 'Listar Categorias',
          icon: FolderPlus,
          path: '/admin/categories'
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen bg-gray-50">
      <AdminSidebar 
        isSidebarOpen={isSidebarOpen}
        isBlogMenuOpen={isBlogMenuOpen}
        setIsBlogMenuOpen={setIsBlogMenuOpen}
        isSettingsMenuOpen={isSettingsMenuOpen}
        setIsSettingsMenuOpen={setIsSettingsMenuOpen}
        handleLogout={handleLogout}
        menuItems={menuItems}
      />

      <div className={`lg:ml-64 ${isSidebarOpen ? 'ml-64' : 'ml-0'}`}>
        <AdminHeader 
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
          userFullName={userFullName}
        />

        <main className="pt-16 pb-16">
          {children}
        </main>

        <AdminFooter />
      </div>
    </div>
  );
}