import { MapPin, Mail, Phone } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';

export const ContactInfo = () => {
  const { data: siteInfo, isLoading } = useQuery({
    queryKey: ['site-info'],
    queryFn: async () => {
      console.log('Fetching site info for ContactInfo...');
      const { data, error } = await supabase
        .from('site_info')
        .select('address, email, phone')
        .limit(1)
        .single();

      if (error) {
        console.error('Error fetching site info in ContactInfo:', error);
        throw error;
      }
      
      console.log('Site info data received:', data);
      return data;
    },
    retry: 3,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  if (isLoading) {
    return (
      <div className="bg-gradient-to-br from-primary/10 to-accent/10 rounded-2xl p-8">
        <div className="animate-pulse space-y-4">
          <div className="h-6 bg-gray-200 rounded w-1/3"></div>
          <div className="space-y-3">
            <div className="h-4 bg-gray-200 rounded"></div>
            <div className="h-4 bg-gray-200 rounded w-5/6"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gradient-to-br from-primary/10 to-accent/10 rounded-2xl p-8">
      <h2 className="text-2xl font-bold mb-6">Informações de Contato</h2>
      <div className="space-y-6">
        <div className="flex items-start">
          <MapPin className="text-primary mt-1 mr-4" size={24} />
          <div>
            <h3 className="font-semibold mb-1">Endereço</h3>
            <p className="text-gray-600">
              {siteInfo?.address || 'Rua Walter Hubacher, 1808 - Centro Nova Andradina - MS'}
            </p>
          </div>
        </div>
        <div className="flex items-start">
          <Mail className="text-primary mt-1 mr-4" size={24} />
          <div>
            <h3 className="font-semibold mb-1">E-mail</h3>
            <p className="text-gray-600">
              {siteInfo?.email || 'atendimento@agenciaw3.digital'}
            </p>
          </div>
        </div>
        <div className="flex items-start">
          <Phone className="text-primary mt-1 mr-4" size={24} />
          <div>
            <h3 className="font-semibold mb-1">Telefone</h3>
            <p className="text-gray-600">
              {siteInfo?.phone || '(67) 3441-9114'}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};