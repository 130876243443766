import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';

export interface Profile {
  id: string;
  full_name: string | null;
  email: string;
  role: string | null;
  avatar_url: string | null;
}

export function useUsers() {
  return useQuery({
    queryKey: ['profiles'],
    queryFn: async () => {
      console.log('Fetching profiles...');
      
      // Primeiro, buscar todos os perfis
      const { data: profilesData, error: profilesError } = await supabase
        .from('profiles')
        .select('*');

      if (profilesError) {
        console.error('Error fetching profiles:', profilesError);
        throw profilesError;
      }

      if (!profilesData) {
        console.log('No profiles found');
        return [];
      }

      // Depois, buscar os emails usando a função get_auth_users
      const { data: emailsData, error: emailsError } = await supabase
        .rpc('get_auth_users');

      if (emailsError) {
        console.error('Error fetching emails:', emailsError);
        throw emailsError;
      }

      console.log('Profiles data:', profilesData);
      console.log('Emails data:', emailsData);

      // Combinar os dados
      const combinedData = profilesData.map(profile => {
        const userEmail = emailsData?.find(e => e.id === profile.id)?.email || '';
        
        if (!userEmail) {
          console.warn('Profile missing email:', profile);
        }
        
        return {
          id: profile.id,
          full_name: profile.full_name,
          role: profile.role,
          avatar_url: profile.avatar_url,
          email: userEmail
        };
      });

      console.log('Combined data:', combinedData);
      return combinedData as Profile[];
    }
  });
}