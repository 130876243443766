import { z } from 'zod';

export const postFormSchema = z.object({
  title: z.string().min(3, 'O título deve ter pelo menos 3 caracteres'),
  excerpt: z.string().min(10, 'O resumo deve ter pelo menos 10 caracteres'),
  content: z.string().min(10, 'O conteúdo deve ter pelo menos 10 caracteres'),
  image_url: z.string({
    required_error: 'Faça upload de uma imagem para o post'
  }),
  published: z.boolean().default(false),
  published_at: z.date().nullable(),
  category_id: z.string().uuid().nullable(),
});

export type PostFormData = z.infer<typeof postFormSchema>;

export interface PostData extends Omit<PostFormData, 'published_at'> {
  id?: string;
  slug?: string;
  published_at: string | null;
  created_by: string;
  updated_by: string;
  created_at?: string;
  updated_at?: string;
}