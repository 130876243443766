import { BaseLayout } from '../components/layouts/BaseLayout';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { supabase } from '@/integrations/supabase/client';
import { Helmet } from 'react-helmet-async';
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";
import { ServiceHero } from '@/components/services/ServiceHero';
import { ServiceContent } from '@/components/services/ServiceContent';
import { ServiceBenefits } from '@/components/services/ServiceBenefits';
import { useEffect } from 'react';

interface Service {
  id: string;
  title: string;
  slug: string;
  description: string;
  content: string;
  image_url?: string;
  benefits: string[];
  created_at: string;
  updated_at: string;
  created_by: string;
  updated_by: string;
}

const ServiceDetails = () => {
  const { slug } = useParams();
  const navigate = useNavigate();

  const { data: service, isLoading, error } = useQuery({
    queryKey: ['service', slug],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('services')
        .select('*')
        .eq('slug', slug)
        .single();
      
      if (error) throw error;
      
      // Garantir que benefits é sempre um array
      const parsedData = {
        ...data,
        benefits: Array.isArray(data.benefits) ? data.benefits : []
      } as Service;
      
      return parsedData;
    }
  });

  useEffect(() => {
    if (error) {
      console.error('Error loading service:', error);
      navigate('/404');
    }
  }, [error, navigate]);

  // Schema.org JSON-LD para o serviço
  const serviceSchema = service ? {
    "@context": "https://schema.org",
    "@type": "Service",
    "name": service.title,
    "description": service.description,
    "provider": {
      "@type": "Organization",
      "name": "Agência W3 Digital",
      "url": "https://agenciaw3.digital"
    },
    "image": service.image_url ? `https://agenciaw3.digital${service.image_url}` : undefined,
    "url": `https://agenciaw3.digital/servicos/${service.slug}`,
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock"
    }
  } : null;

  if (isLoading) {
    return (
      <BaseLayout>
        <div className="animate-pulse">
          <div className="h-[400px] bg-gray-200" />
          <div className="container mx-auto px-4 py-8">
            <div className="h-8 bg-gray-200 w-1/3 mb-4" />
            <div className="space-y-4">
              <div className="h-4 bg-gray-200 w-full" />
              <div className="h-4 bg-gray-200 w-5/6" />
              <div className="h-4 bg-gray-200 w-4/6" />
            </div>
          </div>
        </div>
      </BaseLayout>
    );
  }

  if (!service) {
    return null;
  }

  return (
    <BaseLayout>
      <Helmet>
        <title>{`${service.title} | Agência W3 Digital`}</title>
        <meta name="description" content={service.description} />
        <meta 
          name="keywords" 
          content={`${service.title.toLowerCase()}, agência digital, tecnologia, desenvolvimento web, serviços digitais`} 
        />
        <link rel="canonical" href={`https://agenciaw3.digital/servicos/${service.slug}`} />
        {serviceSchema && (
          <script type="application/ld+json">
            {JSON.stringify(serviceSchema)}
          </script>
        )}
      </Helmet>

      <article className="min-h-screen pt-20">
        <ServiceHero
          title={service.title}
          description={service.description}
          imageUrl={service.image_url}
        />

        <div className="container mx-auto px-4 py-8">
          <Breadcrumb className="mb-8">
            <BreadcrumbList>
              <BreadcrumbItem>
                <Link to="/" className="transition-colors hover:text-foreground">
                  Início
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <Link to="/servicos" className="transition-colors hover:text-foreground">
                  Serviços
                </Link>
              </BreadcrumbItem>
              <BreadcrumbSeparator />
              <BreadcrumbItem>
                <BreadcrumbPage>{service.title}</BreadcrumbPage>
              </BreadcrumbItem>
            </BreadcrumbList>
          </Breadcrumb>

          <div className="max-w-4xl mx-auto">
            <Link
              to="/servicos"
              className="inline-flex items-center text-primary hover:text-primary-dark mb-8"
              aria-label="Voltar para a lista de serviços"
            >
              <ArrowLeft size={20} className="mr-2" aria-hidden="true" />
              Voltar para serviços
            </Link>

            <ServiceContent content={service.content} />
            <ServiceBenefits benefits={service.benefits} />

            <div className="text-center bg-primary/5 rounded-xl p-8">
              <h2 className="text-2xl font-bold mb-4">
                Interessado em nossos serviços?
              </h2>
              <p className="text-gray-600 mb-6">
                Entre em contato conosco para discutir seu projeto e receber um orçamento personalizado.
              </p>
              <Link
                to="/contato"
                className="inline-flex items-center justify-center px-6 py-3 bg-primary text-white rounded-lg hover:bg-primary/90 transition-colors"
                aria-label="Solicitar orçamento para este serviço"
              >
                Solicitar Orçamento
              </Link>
            </div>
          </div>
        </div>
      </article>
    </BaseLayout>
  );
};

export default ServiceDetails;