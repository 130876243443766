import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { supabase } from "@/integrations/supabase/client";
import { useAuth } from "@/hooks/useAuth";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { formSchema } from "./CreateUserFormSchema";
import { UserFormFields } from "./UserFormFields";

interface EditUserFormProps {
  user: {
    id: string;
    email: string;
    full_name: string;
    role?: string;
  };
  isAdmin: boolean;
  onSuccess?: () => void;
}

export function EditUserForm({ user, isAdmin, onSuccess }: EditUserFormProps) {
  const navigate = useNavigate();
  const { user: currentUser } = useAuth();
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      full_name: user.full_name || "",
      email: user.email || "",
      role: user.role || "user",
      password: "",
      confirmPassword: "",
    },
  });

  const onSubmit = async (data: any) => {
    try {
      console.log("Iniciando atualização do usuário", { data });
      
      const updateData: any = {
        full_name: data.full_name,
      };

      if (isAdmin && data.role) {
        updateData.role = data.role;
      }

      console.log("Atualizando perfil com dados:", updateData);
      
      const { error: updateProfileError } = await supabase
        .from('profiles')
        .update(updateData)
        .eq('id', user.id);

      if (updateProfileError) {
        console.error("Erro ao atualizar perfil:", updateProfileError);
        throw updateProfileError;
      }

      // Atualizar email apenas se foi alterado
      if (data.email && data.email !== user.email) {
        console.log("Atualizando email do usuário para:", data.email);
        
        const { data: sessionData } = await supabase.auth.getSession();
        const { error: updateEmailError } = await supabase.functions.invoke(
          'update-user',
          {
            body: JSON.stringify({
              userId: user.id,
              updates: { email: data.email }
            }),
            headers: {
              Authorization: `Bearer ${sessionData.session?.access_token}`,
            }
          }
        );

        if (updateEmailError) {
          console.error("Erro ao atualizar email:", updateEmailError);
          throw updateEmailError;
        }

        if (currentUser?.id === user.id) {
          await supabase.auth.signOut();
          navigate('/admin/login');
          return;
        }
      }

      // Atualizar senha apenas se foi fornecida
      if (data.password && data.password.length > 0) {
        console.log("Atualizando senha do usuário");
        
        const { data: sessionData } = await supabase.auth.getSession();
        const { error: updatePasswordError } = await supabase.functions.invoke(
          'update-user',
          {
            body: JSON.stringify({
              userId: user.id,
              updates: { password: data.password }
            }),
            headers: {
              Authorization: `Bearer ${sessionData.session?.access_token}`,
            }
          }
        );

        if (updatePasswordError) {
          console.error("Erro ao atualizar senha:", updatePasswordError);
          throw updatePasswordError;
        }

        if (currentUser?.id === user.id) {
          await supabase.auth.signOut();
          navigate('/admin/login');
          return;
        }
      }

      toast.success("Usuário atualizado com sucesso!");
      if (onSuccess) onSuccess();

    } catch (error: any) {
      console.error("Erro ao atualizar usuário:", error);
      toast.error(error.message || "Erro ao atualizar usuário");
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <UserFormFields form={form} isEditing={true} isAdmin={isAdmin} />
        <Button type="submit">Salvar alterações</Button>
      </form>
    </Form>
  );
}