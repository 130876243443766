import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import { useAuth } from '@/hooks/useAuth';
import { ServiceContentField } from './ServiceContentField';
import { IconSelect } from './IconSelect';
import { serviceFormSchema, type ServiceFormData, type ServiceData } from './serviceSchema';
import { ServiceTitleField } from './form/ServiceTitleField';
import { ServiceDescriptionField } from './form/ServiceDescriptionField';
import { ServiceImageField } from './form/ServiceImageField';
import { ServiceBenefitsField } from './form/ServiceBenefitsField';
import { ServiceStatusField } from './form/ServiceStatusField';
import { type Json } from '@/integrations/supabase/types';

interface ServiceFormProps {
  initialData?: ServiceData;
}

export function ServiceForm({ initialData }: ServiceFormProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { user } = useAuth();
  const navigate = useNavigate();

  const form = useForm<ServiceFormData>({
    resolver: zodResolver(serviceFormSchema),
    defaultValues: initialData ? {
      title: initialData.title,
      description: initialData.description,
      content: initialData.content,
      status: initialData.status,
      icon_name: initialData.icon_name || 'code',
      image_url: initialData.image_url || '',
      benefits: initialData.benefits || [],
    } : {
      title: '',
      description: '',
      content: '',
      status: true,
      icon_name: 'code',
      image_url: '',
      benefits: [],
    },
  });

  const onSubmit = async (data: ServiceFormData) => {
    if (!user?.id) {
      toast.error("Você precisa estar logado para realizar esta ação.");
      return;
    }

    try {
      setIsLoading(true);
      
      const serviceData = {
        title: data.title,
        description: data.description,
        content: data.content,
        status: data.status,
        icon_name: data.icon_name,
        image_url: data.image_url,
        created_by: user.id,
        updated_by: user.id,
        slug: data.title.toLowerCase().replace(/[^a-z0-9]+/g, '-'),
        benefits: data.benefits as Json,
      };

      const { error } = initialData?.id
        ? await supabase
            .from('services')
            .update(serviceData)
            .eq('id', initialData.id)
        : await supabase
            .from('services')
            .insert(serviceData);

      if (error) throw error;

      toast.success(initialData ? "Serviço atualizado com sucesso!" : "Serviço cadastrado com sucesso!");
      navigate('/admin/services');
      
    } catch (error) {
      console.error('Erro ao salvar serviço:', error);
      toast.error("Ocorreu um erro ao salvar o serviço.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <ServiceTitleField form={form} />
        <ServiceDescriptionField form={form} />
        <FormField
          control={form.control}
          name="icon_name"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Ícone</FormLabel>
              <FormControl>
                <IconSelect 
                  value={field.value}
                  onChange={field.onChange}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <ServiceImageField form={form} />
        <ServiceContentField form={form} />
        <ServiceBenefitsField form={form} />
        <ServiceStatusField form={form} />

        <div className="flex justify-end">
          <Button type="submit" disabled={isLoading}>
            {isLoading ? "Salvando..." : (initialData ? "Atualizar" : "Cadastrar")}
          </Button>
        </div>
      </form>
    </Form>
  );
}