import { ReactNode } from 'react';

interface BlogHeroProps {
  title: string;
  description: string;
}

export const BlogHero = ({ title, description }: BlogHeroProps) => {
  return (
    <div className="text-center mb-16 animate-fade-up">
      <h1 className="text-4xl md:text-5xl font-bold mb-6 gradient-text">
        {title}
      </h1>
      <p className="text-xl text-gray-600 max-w-3xl mx-auto">
        {description}
      </p>
    </div>
  );
};