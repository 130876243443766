import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Plus, Tag, Trash2 } from 'lucide-react';
import { supabase } from '@/integrations/supabase/client';
import { toast } from 'sonner';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { Textarea } from '@/components/ui/textarea';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";

const tagSchema = z.object({
  name: z.string().min(1, 'Nome é obrigatório'),
  code: z.string().min(1, 'Código é obrigatório'),
  position: z.enum(['head', 'body'], {
    required_error: "Selecione a posição da tag",
  }),
});

type GoogleTag = z.infer<typeof tagSchema> & { id: string };

export default function GoogleTags() {
  const queryClient = useQueryClient();
  const form = useForm<z.infer<typeof tagSchema>>({
    resolver: zodResolver(tagSchema),
    defaultValues: {
      name: '',
      code: '',
      position: 'head',
    },
  });

  const { data: tags, isLoading } = useQuery({
    queryKey: ['google-tags'],
    queryFn: async () => {
      const { data, error } = await supabase
        .from('google_tags')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      return data as GoogleTag[];
    },
  });

  const addMutation = useMutation({
    mutationFn: async (values: z.infer<typeof tagSchema>) => {
      const { data, error } = await supabase
        .from('google_tags')
        .insert([values]);

      if (error) throw error;
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['google-tags'] });
      form.reset();
      toast.success('Tag adicionada com sucesso!');
    },
    onError: (error) => {
      console.error('Erro ao adicionar tag:', error);
      toast.error('Erro ao adicionar tag');
    },
  });

  const deleteMutation = useMutation({
    mutationFn: async (id: string) => {
      const { error } = await supabase
        .from('google_tags')
        .delete()
        .eq('id', id);

      if (error) throw error;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['google-tags'] });
      toast.success('Tag removida com sucesso!');
    },
    onError: (error) => {
      console.error('Erro ao remover tag:', error);
      toast.error('Erro ao remover tag');
    },
  });

  if (isLoading) {
    return (
      <div className="p-8">
        <div className="flex items-center justify-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-8">
      <div className="mb-8">
        <h1 className="text-3xl font-bold">Footer e Header</h1>
        <p className="text-gray-600">Gerencie os scripts do header e footer do site</p>
      </div>

      <div className="grid gap-8 lg:grid-cols-2">
        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <h2 className="text-xl font-semibold mb-4">Adicionar Novo Script</h2>
          <Form {...form}>
            <form onSubmit={form.handleSubmit((data) => addMutation.mutate(data))} className="space-y-6">
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Nome do Script</FormLabel>
                    <FormControl>
                      <Input placeholder="Ex: Google Analytics" {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="position"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Posição</FormLabel>
                    <Select onValueChange={field.onChange} defaultValue={field.value}>
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Selecione a posição" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        <SelectItem value="head">Head</SelectItem>
                        <SelectItem value="body">Body</SelectItem>
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <FormField
                control={form.control}
                name="code"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Código do Script</FormLabel>
                    <FormControl>
                      <Textarea
                        placeholder="Cole o código do script aqui"
                        className="font-mono"
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />

              <Button type="submit" disabled={addMutation.isPending}>
                <Plus className="w-4 h-4 mr-2" />
                Adicionar Script
              </Button>
            </form>
          </Form>
        </div>

        <div className="bg-white p-6 rounded-lg shadow-sm border border-gray-200">
          <h2 className="text-xl font-semibold mb-4">Scripts Cadastrados</h2>
          <div className="space-y-4">
            {tags?.length === 0 ? (
              <p className="text-gray-500">Nenhum script cadastrado</p>
            ) : (
              tags?.map((tag) => (
                <div
                  key={tag.id}
                  className="p-4 border border-gray-200 rounded-lg flex items-center justify-between"
                >
                  <div className="flex items-center space-x-3">
                    <Tag className="w-5 h-5 text-primary" />
                    <div>
                      <span className="font-medium">{tag.name}</span>
                      <span className="text-sm text-gray-500 ml-2">({tag.position})</span>
                    </div>
                  </div>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={() => deleteMutation.mutate(tag.id)}
                    className="text-red-500 hover:text-red-600 hover:bg-red-50"
                  >
                    <Trash2 className="w-4 h-4" />
                  </Button>
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </div>
  );
}